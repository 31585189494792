import { useTranslation } from 'react-i18next'
import { MicroservicesDesigner } from './MicroservicesDesigner'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorageSearchParams } from '../../../hooks/utils/useLocalStorageSearchParams'

const MicroservicesDesignerPageBody = () => {
  const { t } = useTranslation('microservices')
  const { selectedOrganizationId } = useOrganizationSelection()
  const navigate = useNavigate()
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}${finalSearchParams}`)
  }, [selectedOrganizationId])

  return (
    <TablePageBody
      isLoading={false}
      title={`${t('DESIGNER.TITLE')}`}
      titleTag={PRODUCT_ENV !== ProductEnvTypes.FXXONE ? 'PREVIEW' : undefined}
    >
      {selectedOrganizationId && <MicroservicesDesigner organizationId={selectedOrganizationId} />}
    </TablePageBody>
  )
}

export const MicroservicesDesignerPage = withFeatureRender(
  MicroservicesDesignerPageBody,
  FEATURE_NAMES.MICROSERVICES_READ
)
