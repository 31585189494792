import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { MicroserviceEvent } from 'app/models/microservices'
import { TFunction } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  t: TFunction
  onExportClicked: () => void
}

export const useMicroservicesAuditLogActions = ({ t, onExportClicked }: Props) => {
  const navigate = useNavigate()

  const onViewDetailsClicked = (microserviceEvent: MicroserviceEvent) => {
    navigate(`/microservices-events/${microserviceEvent._id}`)
  }

  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  return {
    commandBarActions,
    onViewDetailsClicked,
  }
}
