import { EmptyStatus, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { SelectionMode } from '@fluentui/react'
import { Tenant } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import { useOrgTenantsFilters } from './useOrgTenantsFilters'
import { usePaginatedTenantsQuery } from './usePaginatedTenantsQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { useOrgTenantsActions } from './useOrgTenantsActions'
import { useOrgTenantsBuildTable } from './useOrgTenantsBuildTable'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import { useOrgTenantsBuildExport } from './useOrgTenantsBuildExport'
import useGetBusinessTenants from 'app/hooks/tenant/useGetBusinessTenants'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

interface Props {
  organizationId: string
}

export const TenantsFeature = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const { checkFeature } = useFeatureRender()

  // Request data
  const { filterFields } = useOrgTenantsFilters({ t })
  const { query, queryName } = usePaginatedTenantsQuery('TenantFilter', 'TenantSorting')
  const { data: paginatedTenants, refetch } = usePaginatedData<Tenant>(query, queryName, filterFields, {
    businessId: organizationId,
  })

  // Export
  const { tenantTableExportColumns } = useOrgTenantsBuildExport()
  const { refetch: refetchExportTenants } = useGetBusinessTenants(organizationId)
  const { onExportClicked } = useTableExport({
    query: refetchExportTenants,
    columns: tenantTableExportColumns,
    fileName: 'Tenants list',
  })

  // Actions and columns
  const { openAddTenant, openEditTenant, commandBarActions, canCreate } = useOrgTenantsActions({
    t,
    organizationId,
    onExportClicked,
  })
  const { columns } = useOrgTenantsBuildTable({ t, i18n, organizationId, openEditTenant })

  return (
    <PaginatedTable
      tableId="tenants"
      locale={i18n.language}
      paginatedData={paginatedTenants}
      columns={columns}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      onRefresh={refetch}
      commands={commandBarActions}
      isFetching={false}
      selectionMode={SelectionMode.none}
      emptyInitialLoadContent={
        <div style={{ marginTop: 100 }}>
          <EmptyStatus
            message={t('TENANTS.EMPTY_MESSAGE')}
            buttonProps={
              canCreate && checkFeature(FEATURE_NAMES.TENANT_CREATE)
                ? {
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                    onClick: openAddTenant,
                    disabled: !canCreate,
                  }
                : undefined
            }
          />
        </div>
      }
    />
  )
}
