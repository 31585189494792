import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
}

export const useMicroservicesMarketplaceFilters = ({ t }: Props) => {
  useLocalStorageSearchParams()

  const filterFields: FilterField[] = [
    {
      field: 'name',
      text: t('MARKETPLACE.TABLE.NAME'),
      type: FieldTypes.STRING,
    },
    {
      field: 'category',
      text: t('MARKETPLACE.TABLE.CATEGORY'),
      type: FieldTypes.STRING,
    },
    {
      field: 'organizationName',
      text: t('MARKETPLACE.TABLE.LIBRARY'),
      type: FieldTypes.STRING,
    },
    {
      field: 'active',
      text: t('MICROSERVICE_STATUS.ACTIVE'),
      type: FieldTypes.BOOLEAN,
      options: [
        { key: 'true', text: t('MICROSERVICE_STATUS.ACTIVE') },
        { key: 'false', text: t('MICROSERVICE_STATUS.INACTIVE') },
      ],
    },
  ]

  return {
    filterFields,
  }
}
