import { pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const useMicroservicesAuditLogQuery = () => {
  const queryName = 'getPaginatedMicroserviceEvents'

  const query = gql`
		query Request(
			$businessId: ID!
			$language: String!
			$page: Int!
			$perPage: Int!
			$searchTerm: String!
			$filters: PaginatedMicroserviceAuditLogFiltersGraphql!
			$sorting: PaginatedMicroserviceAuditLogSortingGraphql!
		) {
			getPaginatedMicroserviceEvents(
				businessId: $businessId
				language: $language
				${paginatedVarsBlock}
			) {
				data {
					_id
					type
					microservice {
						_id
						name {
							code
							text
							translatedByIA
						}
					}
					initiatedBy
					status
					duration
					data
					executionStartTime
					executionEndTime
					loggedAt
					reportStartTime
					reportEndTime
					business {
						_id
						name
					}
				}
				${pageInfoBlock}
			}
		}
	`

  return {
    query,
    queryName,
  }
}
