import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
  onExportClicked: () => void
}

export const useMicroservicesMarketplaceActions = ({ t, onExportClicked }: Props) => {
  const commands: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  return {
    commands,
  }
}
