import { TooltipDelay, TooltipHost, TooltipOverflowMode } from '@fluentui/react'
import { MarketplaceMicroserviceView } from 'app/hooks/microservices/useGetMarketplaceMicroservices'
import { getTranslation } from 'app/models/microservices'
import i18next from 'i18next'

export const MicroserviceCategory = (item: MarketplaceMicroserviceView) => {
  const category = getTranslation(item.category, i18next.language)
  const itemCategory = category.text

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <TooltipHost
          content={itemCategory?.toString()}
          delay={TooltipDelay.zero}
          overflowMode={TooltipOverflowMode.Parent}
        >
          {itemCategory?.toString()}
        </TooltipHost>
      </div>
    </div>
  )
}
