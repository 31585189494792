import { useQuery, UseQueryResult } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { getTranslation, MultiLanguageField } from 'app/models/microservices'
import { TFunction } from 'react-i18next'
import i18next from 'i18next'

export interface MarketplaceMicroserviceView {
  id: string
  name: MultiLanguageField[]
  active: boolean
  description: MultiLanguageField[]
  category: MultiLanguageField[]
  iconName: string
  iconBackground: string
  organization: {
    name: string
    isTrial: boolean
  }
  createdAt: Date
  library: string
}

interface Response {
  getMarketplaceMicroservices: Array<MarketplaceMicroserviceView>
}

const getMicroservicesQuery = gql`
  query ($businessId: ID!) {
    getMarketplaceMicroservices(businessId: $businessId) {
      id
      name {
        code
        text
        translatedByIA
      }
      active
      description {
        code
        text
        translatedByIA
      }
      category {
        code
        text
        translatedByIA
      }
      iconName
      iconBackground
      organization {
        name
        isTrial
      }
      createdAt
    }
  }
`

export function getMarketplaceMicroservices(businessId: string, t: TFunction): Promise<MarketplaceMicroserviceView[]> {
  return graphClient.request(getMicroservicesQuery, { businessId }).then((response) => {
    if (!response.getMarketplaceMicroservices) {
      return []
    }

    const _services = response.getMarketplaceMicroservices.map((item: MarketplaceMicroserviceView) => {
      const _name = getTranslation(item.name, i18next.language).text
      const _category = getTranslation(item.category, i18next.language).text

      return {
        name: _name,
        category: _category,
        active: item.active,
        organizationName: item.organization.name,
      }
    })

    return _services
  })
}

export const useGetMarketplaceMicroservices = (
  organizationId: string,
  t: TFunction
): UseQueryResult<MarketplaceMicroserviceView[]> => {
  if (!organizationId) {
    return useQuery(['microservices'], () => [], {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['microservices', organizationId], () => getMarketplaceMicroservices(organizationId, t), {
    refetchOnWindowFocus: false,
  })
}
