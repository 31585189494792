import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import {
  DesignerMicroserviceView,
  useGetDesignerMicroservices,
} from '../../../hooks/microservices/useGetDesignerMicroservices'
import i18next from 'i18next'
import { useMe } from 'app/hooks/me/useMe'
import { SpecialPermission } from 'app/query-client/types'
import { useMicroservicesDesignerBuildTable } from './useMicroservicesDesignerBuildTable'
import { useMicroservicesDesignerActions } from './useMicroservicesDesignerActions'
import { ArchiveConfirmationDialog } from './components/ArchiveConfirmationDialog'
import { UnarchiveConfirmationDialog } from './components/UnarchiveConfirmationDialog'
import { useMicroservicesDesignerFilters } from './useMicroservicesDesignerFilters'
import { useGetPaginatedDesignerMicroservicesQuery } from './useGetPaginatedDesignerMicroservicesQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import EmptyStatus from 'app/components/status/empty-status'
import { useMicroservicesDesignerBuildExport } from './useMicroservicesDesignerBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import { getLanguageCode } from 'app/i18n/languages'

export const MicroservicesDesigner = ({ organizationId, contextActionsEnabled = true }: { organizationId?: string, contextActionsEnabled?: boolean }) => {
  const { t } = useTranslation('microservices')
  const { data: me } = useMe()

  const canSeeNonStandardMicroservices =
    me?.specialPermissions?.includes(SpecialPermission.CONDITION_MICROSERVICE) ||
    me?.specialPermissions?.includes(SpecialPermission.CUSTOM_FIELDS_MICROSERVICE)

  // Request data
  const { filterFields } = useMicroservicesDesignerFilters({ t, canSeeNonStandardMicroservices })
  const { query, queryName } = useGetPaginatedDesignerMicroservicesQuery()
  const { data: paginatedDesignerMicroservices, isFetching, refetch: refetchMicroservices } = usePaginatedData<DesignerMicroserviceView>(
    query,
    queryName,
    filterFields,
    { businessId: organizationId, language: getLanguageCode(i18next.language) }
  )

  // Export
  const { refetch: refetchDesignerMicroservices } = useGetDesignerMicroservices(organizationId)
  const { exportColumns, exportMapper } = useMicroservicesDesignerBuildExport(t, canSeeNonStandardMicroservices!)
  const { onExportClicked } = useTableExport({
    query: refetchDesignerMicroservices,
    columns: exportColumns,
    fileName: t('DESIGNER.TITLE'),
    exportMapper
  })

  // Actions and columns
  const { handleOnClickViewDetails,
    onClickEdit,
    onClickUnarchive,
    onClickArchive,
    hideArchiveDialog,
    hideUnarchiveDialog,
    toggleHideArchiveDialog,
    toggleHideUnarchiveDialog,
    selectedMicroservice,
    onConfirmArchive,
    onConfirmUnarchive,
    commandBarActions } = useMicroservicesDesignerActions({ t, onExportClicked })

  const { columns } = useMicroservicesDesignerBuildTable({
    t,
    contextActionsEnabled,
    handleOnClickViewDetails,
    onClickEdit,
    onClickArchive,
    onClickUnarchive,
    canSeeNonStandardMicroservices
  })

  return (
    <>
      <PaginatedTable
        tableId="enabled-microservices"
        paginatedData={paginatedDesignerMicroservices}
        onRefresh={async ()=>{
          await refetchMicroservices()
        }}
        filters={{
          filterFields,
          smartSearch: true,
          advancedSearch: true,
        }}
        {...{ columns, isFetching }}
        commands={commandBarActions}
        emptyInitialLoadContent={
          <div style={{ margin: '100px' }}>
            <EmptyStatus message={t('MARKETPLACE.EMPTY_STATUS_MESSAGE')} />
          </div>}
      />

      <ArchiveConfirmationDialog
        microservice={selectedMicroservice!}
        t={t} isOpen={!hideArchiveDialog}
        onDismiss={toggleHideArchiveDialog}
        onConfirm={onConfirmArchive}
      />
      <UnarchiveConfirmationDialog
        microservice={selectedMicroservice!}
        t={t} isOpen={!hideUnarchiveDialog}
        onDismiss={toggleHideUnarchiveDialog}
        onConfirm={onConfirmUnarchive}
      />
    </>
  )
}