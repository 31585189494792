import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { Workspace } from 'app/query-client/types'
import { gql } from 'graphql-request'
import _ from 'lodash'

const fetchWorkspacesByBusinessQuery = gql`
  query ($organizationId: ID!) {
    fetchWorkspacesByBusiness(organizationId: $organizationId) {
      _id
      name
      fqdn
      ipAddress
      uOperativeSystem
      uCoresCpu
      uRam
      uType
      uLastUser
      organizationName
    }
  }
`

function fetchWorkspacesByBusiness(organizationId: string) {
  return graphClient.request(fetchWorkspacesByBusinessQuery, { organizationId }).then((response) => {
    return response.fetchWorkspacesByBusiness.map((workspace: Workspace) => ({
      ...workspace,
      uCoresCpu: _.toNumber(workspace.uCoresCpu),
      uRam: _.toNumber(workspace.uRam),
    }))
  })
}

export default function (organizationId: string) {
  return useQuery(['fetchWorkspacesByBusiness', organizationId], () => fetchWorkspacesByBusiness(organizationId), {
    enabled: false,
  })
}
