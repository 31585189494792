import { ContextualMenuItemType, IContextualMenuItem } from "@fluentui/react"
import { DesignerMicroserviceView } from "app/hooks/microservices/useGetDesignerMicroservices"
import { TFunction } from "i18next"
import { CSSProperties } from "react"

export const buildContextActions = (microservice: DesignerMicroserviceView,
  t: TFunction,
  onClickView: (item: DesignerMicroserviceView) => void,
  onClickEdit: (item: DesignerMicroserviceView) => void,
  onClickUnarchive: (item: DesignerMicroserviceView) => void,
  onClickArchive: (item: DesignerMicroserviceView) => void): IContextualMenuItem[] => {

  const iconStyles: CSSProperties = {
    fontSize: 'var(--font-size-regular)',
    color: 'var(--color-primary)',
  }
  return [
    {
      key: 'view',
      text: t('DESIGNER.ACTION.VIEW_DETAILS'),
      iconProps: {
        iconName: 'NavigateExternalInline',
        style: iconStyles,
      },
      onClick: () => onClickView(microservice)
    },
    ...(microservice.canEdit
      ? [
        {
          key: 'edit',
          text: t('DESIGNER.ACTION.EDIT'),
          iconProps: {
            iconName: 'Edit',
            style: iconStyles,
          },
          onClick: () => onClickEdit(microservice)
        },
        {
          key: 'divider_1',
          itemType: ContextualMenuItemType.Divider,
        },
        microservice.archivedAt
          ? {
            key: 'unarchive',
            text: t('DESIGNER.ACTION.UNARCHIVE'),
            iconProps: {
              iconName: 'PublishContent',
              style: {
                ...iconStyles,
                color: '#d83b01',
              },
            },
            onClick: () => onClickUnarchive(microservice)
          }
          : {
            key: 'archive',
            text: t('DESIGNER.ACTION.ARCHIVE'),
            iconProps: {
              iconName: 'Archive',
              style: {
                ...iconStyles,
                color: '#d83b01',
              },
            },
            onClick: () => onClickArchive(microservice)
          },
      ]
      : []),
  ]
}