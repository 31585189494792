import { PaginatedTable, ICommandItem } from '@flexxibleit/flexxible-ui'
import useCreateForm from 'app/hooks/useCreateForm'
import { UserRole } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RolInfoForm } from './tabs/details/RolInfoForm'
import { useRolesBuildTable } from './useRolesBuildTable'
import { useRolesFilters } from './useRolesFilters'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { usePaginatedUserRoleQuery } from './usePaginatedBusinessRolesQuery'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import useGetRolesByOrganization from 'app/hooks/roles/useGetRolesByOrganization'
import { useRolesBuildExport } from './useRolesBuildExport'
import { EmptyUserRoles } from './EmptyUserRoles'

interface Props {
  organizationId?: string
}

export const RolesFeature = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const navigate = useNavigate()
  const { filterFields } = useRolesFilters({ t })
  const { query } = usePaginatedUserRoleQuery()

  const { data: paginatedRoles, isFetching, refetch: refetchRoles } = usePaginatedData<UserRole>(
    query,
    'getPaginatedUserRoles',
    filterFields,
    {
      organizationId: organizationId,
    }
  )

  // Export
  const { exportColumns } = useRolesBuildExport()
  const { refetch: refetchRolesByOrganization } = useGetRolesByOrganization(organizationId || '')

  const { onExportClicked } = useTableExport({
    query: refetchRolesByOrganization,
    columns: exportColumns,
    fileName: 'Roles by organization',
  })

  const onCreate = useCreateForm(
    {
      title: t('ROLES.FORM.CREATE_TITLE'),
      isFooterAtBottom: true,
    },
    <RolInfoForm organizationId={organizationId || ''} />
  )
  const onViewDetails = (role: UserRole) => {
    navigate(`/organization/${organizationId}/roles/${role._id}`)
  }

  const commandBarActions: ICommandItem[] = [
    {
      key: 'create',
      text: t('general:BUTTON.CREATE'),
      iconProps: { iconName: 'Add' },
      onClick: () => onCreate(),
    },
    {
      key: 'export',
      text: t('ROLES.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => onExportClicked(),
    },
  ]

  const { columns } = useRolesBuildTable({ t, i18n, onViewDetails })

  return (
    <PaginatedTable
      tableId="roles"
      locale={i18n.language}
      paginatedData={paginatedRoles}
      onRefresh={async () => {
        await refetchRoles()
      }}
      columns={columns}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      commands={commandBarActions}
      isFetching={isFetching}
      emptyInitialLoadContent={<EmptyUserRoles {...{ t, onCreate }} />}
    />
  )
}
