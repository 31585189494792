import { Module } from 'app/query-client/types'
import { useMemo } from 'react'
import { H1Card } from './H1Card.component'
import { H1CardDownload } from './H1CardDownload.component'
import { TFunction } from 'i18next'

type MainModulesProps = {
  modules: Module[],
  getModuleType: Function,
  handleModuleClick: Function,
  handleClickDownload: Function,
  selectedOrganizationId: string,
  canSeeDownload: boolean,
  t: TFunction
}

export const MainModules = ({ modules, getModuleType, handleModuleClick, canSeeDownload, selectedOrganizationId, handleClickDownload, t }: MainModulesProps) => {

  const visibility1List = useMemo(
    () => modules.filter((module) => module.visibility === 1).sort((a, b) => a.order - b.order),
    [modules]
  )

  return (
    visibility1List.length && (
      <div className="d-flex d-flexRow d-flex d-flexWrap" style={{ gap: 20, marginTop: 20 }}>
        {visibility1List.map((module, index) => (
          <H1Card key={module._id} onClick={handleModuleClick} {...{ module, index, getModuleType }} />
        ))}
        {canSeeDownload && (
          <H1CardDownload  {...{ selectedOrganizationId, handleClickDownload, t }} />
        )}
      </div>
    ) || <></>
  )
}


