import { LoadingSpinner, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import useGetActivations, { useGetActivationsNoPagination } from 'app/hooks/tenant/activations/useGetActivations'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import { useActivationBuildTable } from './useActivationBuildTable'
import { useActivationFilters } from './useActivationFilters'
import { useActivationBuildExport } from './useActivationBuildExport'
import { useEffect, useState } from 'react'

interface TableActivationProps {
  organizationId: string
}

const TableActivation = ({ organizationId }: TableActivationProps) => {
  const { t, i18n } = useTranslation('organization_details')

  const { filterFields } = useActivationFilters({ t })
  const [isRefreshing, setRefreshing] = useState(false)

  const { data, refetch: refetchData } = useGetActivations(organizationId || '', filterFields)
  const { refetch } = useGetActivationsNoPagination(organizationId || '')

  const { columns } = useActivationBuildTable({ t })

  const { exportColumns } = useActivationBuildExport({ t })

  const { onExportClicked } = useTableExport({
    query: refetch,
    columns: exportColumns,
    fileName: 'ActivationTenants',
  })

  return (
    <PaginatedTable
      tableId={'tenantsActivation'}
      locale={i18n.language}
      paginatedData={data}
      commands={[
        {
          key: 'export',
          text: t('TENANTS_ACTIVATIONS.EXPORT_TITLE'),
          iconProps: { iconName: 'ExcelDocument' },
          onClick: onExportClicked,
        },
      ]}
      onRefresh={refetchData}
      filters={{
        filterFields: filterFields,
        advancedSearch: true,
        smartSearch: true,
      }}
      isFetching={false}
      columns={columns}
      emptyInitialLoadContent={<LoadingSpinner />}
      tableProps={{ onShouldVirtualize: () => true }}
    />
  )
}

export default TableActivation
