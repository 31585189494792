import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { Workspace } from 'app/query-client/types'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
  showTenant?: boolean
}

function getUTypeDisplayValue(uType?: string): string {
  if (!uType) {
    return ''
  }
  if (uType.toLowerCase() == 'physical') {
    return 'Physical'
  }
  if (uType.toLowerCase() == 'virtual') {
    return 'Virtual'
  }
  return uType
}

export const useWorkspacesBuildTable = ({ t, showTenant }: Props) => {
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  useEffect(() => {
    setColumns([
      ...(showTenant
        ? [
            {
              key: 'organizationName',
              name: t('TABLE.TENANT_NAME'),
              fieldName: 'organizationName',
              isRowHeader: true,
            },
          ]
        : []),
      {
        key: 'name',
        name: t('TABLE.NAME'),
        fieldName: 'name',
        isRowHeader: true,
        onRender: ({ name }: Workspace) => {
          return <span>{name}</span>
        },
      },
      {
        key: 'fqdn',
        name: t('TABLE.FQDN'),
        fieldName: 'fqdn',
        isRowHeader: true,
        onRender: ({ fqdn }: Workspace) => {
          return <span>{fqdn}</span>
        },
      },
      {
        key: 'ipAddress',
        name: t('TABLE.IP_ADDRESS'),
        fieldName: 'ipAddress',
        isRowHeader: true,
        onRender: ({ ipAddress }: Workspace) => {
          return <span>{ipAddress}</span>
        },
      },
      {
        key: 'uOperativeSystem',
        name: t('TABLE.U_OPERATIVE_SYSTEM'),
        fieldName: 'uOperativeSystem',
        isRowHeader: true,
        onRender: ({ uOperativeSystem }: Workspace) => {
          return <span>{uOperativeSystem}</span>
        },
      },
      {
        key: 'uCoresCpu',
        name: t('TABLE.U_CORES_CPU'),
        fieldName: 'uCoresCpu',
        isRowHeader: true,
        onRender: ({ uCoresCpu }: Workspace) => {
          return <span>{uCoresCpu}</span>
        },
      },
      {
        key: 'uRam',
        name: t('TABLE.U_RAM'),
        fieldName: 'uRam',
        isRowHeader: true,
        onRender: ({ uRam }: Workspace) => {
          return <span>{uRam}</span>
        },
      },
      {
        key: 'uType',
        name: t('TABLE.U_TYPE'),
        fieldName: 'uType',
        isRowHeader: true,
        onRender: ({ uType }: Workspace) => {
          return <span>{getUTypeDisplayValue(uType)}</span>
        },
      },
      {
        key: 'uLastUser',
        name: t('TABLE.U_LAST_USER'),
        fieldName: 'uLastUser',
        isRowHeader: true,
        onRender: ({ uLastUser }: Workspace) => {
          return <span>{uLastUser}</span>
        },
      },
    ])
  }, [showTenant])

  return {
    columns,
  }
}
