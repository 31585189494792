import { MessageBar } from "@fluentui/react"
import { ConfirmationDialog } from "app/components/ConfirmationDialog/ConfirmationDialog"
import { DesignerMicroserviceView } from "app/hooks/microservices/useGetDesignerMicroservices"
import { getTranslation } from "app/models/microservices"
import i18next, { TFunction } from "i18next"
import { ReactI18NextChild } from "react-i18next"

type archiveConfirmationModalProps = {
  microservice: DesignerMicroserviceView,
  t: TFunction,
  isOpen: boolean,
  onDismiss: () => void,
  onConfirm: (item: DesignerMicroserviceView) => void
}

export const ArchiveConfirmationDialog = ({ microservice, t, isOpen, onDismiss, onConfirm }: archiveConfirmationModalProps) => {
  const name = getTranslation(microservice ? microservice.name : [], i18next.language)
  const itemName = name.text

  return (
    <ConfirmationDialog
      title={t('DESIGNER.ARCHIVE_DIALOG.TITLE')}
      description={t('DESIGNER.ARCHIVE_DIALOG.DESCRIPTION')}
      textRequired={itemName}
      actionButton={t('DESIGNER.ARCHIVE_DIALOG.BUTTON_ACCEPT')}
      dismissButton={t('DESIGNER.ARCHIVE_DIALOG.BUTTON_CANCEL')}
      hidden={!isOpen}
      onDismiss={onDismiss}
      callback={() => {
        onConfirm(microservice);
      }}
    >
      <MessageBar
        messageBarType={3}
        isMultiline={true}
        dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
        className="mb-2"
        style={{ width: '100%' }}
      >
        {t('DESIGNER.ARCHIVE_DIALOG.WARNING') as ReactI18NextChild}
      </MessageBar>
    </ConfirmationDialog>
  )
}