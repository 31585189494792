import useGetWorkspacesByOrganization from '../../../hooks/workspace/useGetWorkspacesByOrganization'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { PaginationArgs, Workspace } from '../../../query-client/types'
import { ColumnActionsMode, IColumn, IDetailsListStyles, Selection, SelectionMode } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import Table from '../../../components/Table/Table.component'
import { usePaginatedSelection } from '../../../hooks/utils/usePaginatedSelection'

interface Props {
  organizationId: string,
  setSelectedWorkspacesState: Dispatch<SetStateAction<Workspace[]>>,
  searchTerm: string,
  getColumns: () => IColumn[],
}

export const GroupedWorkspacesEditAllWorkspaces = (props: Props) => {
  const { t } = useTranslation('organization_details');

  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 10,
  }));

  const {
    data: workspacesPagination,
    isLoading: isLoadingWorkspaces,
    isFetching: isFetchingWorkspaces
  } = useGetWorkspacesByOrganization(props.organizationId, props.searchTerm, paginationState);

  const availableWorkspacesPage: Workspace[] = useMemo(
    () => workspacesPagination?.results || [],
    [workspacesPagination]
  );
  const columns = useMemo(() => {
    if (workspacesPagination?.results?.[0]?.organizationName) {
      return [
        {
          key: 'organizationName',
          name: t('workspace_groups:TABLE.TENANT_NAME'),
          fieldName: 'organizationName',
          isRowHeader: true,
          minWidth: 100,
          isPadded: true,
          columnActionsMode: ColumnActionsMode.disabled,
        },
        ...props.getColumns(),
      ]
    }
    return props.getColumns()
  }, [workspacesPagination])

  const selection = usePaginatedSelection(
    (item: Workspace) => item.sysId,
    props.setSelectedWorkspacesState
  );

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState);
  }

  const hideSelectAllControlStyle: Partial<IDetailsListStyles> = {
    headerWrapper: {
      '.ms-DetailsHeader-cellIsCheck':{
        visibility: 'hidden'
      }
    }
  };

  const renderTable = () => {
    return (
      <Table
        tableId="all-workspaces"
        isLoading={isLoadingWorkspaces}
        isEmpty={availableWorkspacesPage?.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns,
          items: availableWorkspacesPage,
          selection: selection as Selection,
          selectionMode: SelectionMode.multiple,
          selectionPreservedOnEmptyClick: true,
          isSelectedOnFocus: false,
          styles: hideSelectAllControlStyle,
          onShouldVirtualize: () => false,
        }}
        paginationProps={
          workspacesPagination
            ? {
                pagination: paginationState,
                onPaginationChange: handleOnPaginationChange,
                paginatedData: workspacesPagination,
                isFetching: isFetchingWorkspaces,
              }
            : undefined
        }
      />
    )
  };

  return renderTable();
}
