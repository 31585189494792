import { MOBILE_QUERY, TABLET_QUERY, useMediaQuery } from "@flexxibleit/flexxible-ui"
import FlexxibleCard from "app/components/FlexxibleCard/FlexxibleCard.component"
import { getModuleIcon } from "app/services/module-icon.service"
import { useNavigate } from "react-router-dom"

export const H1CardDownload = ({
  t,
  selectedOrganizationId,
  handleClickDownload
}: {
  t: Function
  selectedOrganizationId: string
  handleClickDownload: Function
}) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const navigate = useNavigate()

  const cardProps = {
    style: isMobile
      ? { width: '100%' }
      : isTablet
        ? { width: 'calc(28% - 10px' }
        : { width: 'calc(20% - 50px)', minWidth: 152 },
    onClick: () => handleClickDownload(navigate, selectedOrganizationId),
  }

  return (
    <>
      <FlexxibleCard styleNames="d-flex d-flexCol d-flexJustifyCenter cursor-pointer" cardProps={cardProps}>
        <div className="d-flex d-center d-flexCol modules__card-link__container">
          <div>{getModuleIcon(`download` || '', 38, 38)}</div>
          <div className="modules__card-link__type">{t('home:DOWNLOAD')}</div>
        </div>
      </FlexxibleCard>
    </>
  )
}