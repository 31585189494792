import { useQuery } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { MicroserviceEvent } from '../../models/microservices/MicroserviceEvent'
import { TFunction } from 'react-i18next'
import { getTranslation, label, labelStatus, MicroserviceEventType } from 'app/models/microservices'
import i18next from 'i18next'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'

const getMicroserviceEventsQuery = gql`
  query getMicroserviceEvents($businessId: ID!) {
    getMicroserviceEvents(businessId: $businessId) {
      _id
      type
      microservice {
        _id
        name {
          code
          text
          translatedByIA
        }
      }
      initiatedBy
      status
      loggedAt
    }
  }
`

function getMicroserviceEvents(businessId: string, t: TFunction): Promise<MicroserviceEvent[]> {
  return graphClient.request(getMicroserviceEventsQuery, { businessId }).then((response) => {
    if (!response.getMicroserviceEvents) return []

    const _events = response.getMicroserviceEvents.map((event: any) => {
      const _name = getTranslation(event.microservice.name, i18next.language).text
      const _type = label(t)[event.type as MicroserviceEventType]

      return {
        _id: event._id,
        microserviceName: _name,
        type: _type,
        initiatedBy: event.initiatedBy,
        status: labelStatus(t, event.status ?? ''),
        loggedAt: dateFormatter(t, new Date(event.loggedAt), DateFormat.DATETIME),
      }
    })

    return _events
  })
}

export const useGetMicroserviceEvents = (businessId: string, t: TFunction) => {
  if (!businessId) {
    return useQuery(['microserviceEvents'], () => [], {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['microserviceEvents', businessId], () => getMicroserviceEvents(businessId, t), {
    refetchOnWindowFocus: false,
  })
}
