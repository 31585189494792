import { TFunction } from 'i18next'

export const useHomeBuildExport = (t: TFunction) => {
  const homeTableExportColumns = [
    {
      key: 'environment',
      header: t('PRODUCTS.TABLE.ENVIRONMENT'),
      width: 10,
    },
    {
      key: 'name',
      header: t('PRODUCTS.TABLE.PRODUCT_TYPE'),
      width: 10,
    },
  ]

  const exportMapper = (item: any) => {
    return {
      environment: item.environment,
      name: item.product.name,
    }
  }

  return { homeTableExportColumns, exportMapper }
}
