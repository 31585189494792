import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { FeedbackMessageBar } from '../../../components/FeedbackMessageBar/FeedbackMessageBar'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { TenantsFeature } from './Tenants.feature'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import useOrganizationExistsOnBuild from 'app/hooks/organization/useOrganizationExistsOnBuild'
import TitleComponent from '../../../components/PageBody/PageTitle.component'
import useOrganizationById from '../../../hooks/organization/useOrganizationById'

const OrgTenants = () => {
  const { t } = useTranslation('organization_details')
  const navigate = useNavigate()

  const { selectedOrganizationId, selectedOrganization } = useOrganizationSelection()
  const { organizationId } = useParams()
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]

  useOrganizationExistsOnBuild(organizationId || '')
  useOrganizationById(organizationId || '')

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}/${selectedOrganizationId}/tenants${finalSearchParams}`)
  }, [selectedOrganizationId])

  return (
    <TablePageBody
      title={selectedOrganization?.name || ''}
      titleComponent={
        <TitleComponent
          title={`${selectedOrganization?.name} - ${t('TENANTS_PIVOT')}`}
          type={selectedOrganization?.type}
        />
      }
    >
      <FeedbackMessageBar />
      <TenantsFeature organizationId={selectedOrganization?._id || ''} />
    </TablePageBody>
  )
}

export const OrgTenantsPage = withFeatureRender(OrgTenants, FEATURE_NAMES.TENANT_READ)
