import { EmptyStatus, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { useMicroservicesMarketplaceFilters } from './useMicroservicesMarketplaceFilters'
import { useMicroservicesMarketplaceQuery } from './useMicroservicesMarketplaceQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { getLanguageCode, LanguageKey } from 'app/i18n/languages'
import { useMicroservicesMarketplaceActions } from './useMicroservicesMarketplaceActions'
import { useMicroservicesMarketplaceBuildTable } from './useMicroservicesMarketplaceBuildTable'
import {
  MarketplaceMicroserviceView,
  useGetMarketplaceMicroservices,
} from 'app/hooks/microservices/useGetMarketplaceMicroservices'
import { MicroservicesCard } from '../components/MicroservicesCard'
import { useNavigate } from 'react-router-dom'
import { useMicroservicesMarketplaceBuildExport } from './useMicroservicesMarketplaceBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'

interface Props {
  organizationId: string
}

export const MicroservicesMarketplace = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('microservices')
  const navigate = useNavigate()
  const lang = getLanguageCode(i18n.language as LanguageKey)

  // Request data
  const { filterFields } = useMicroservicesMarketplaceFilters({ t })
  const { query, queryName } = useMicroservicesMarketplaceQuery()
  const { data: paginatedMicroservices, isFetching, refetch: microservicesRefetch } = usePaginatedData<MarketplaceMicroserviceView>(
    query,
    queryName,
    filterFields,
    {
      businessId: organizationId,
      language: lang,
    }
  )

  // Export
  const { exportColumns } = useMicroservicesMarketplaceBuildExport(t)
  const { refetch: fetchMarketplaceExport } = useGetMarketplaceMicroservices(organizationId, t)
  const { onExportClicked } = useTableExport({
    query: fetchMarketplaceExport,
    columns: exportColumns,
    fileName: 'Microservices Marketplace List',
  })

  // Actions and columns
  const { commands } = useMicroservicesMarketplaceActions({ t, onExportClicked })
  const { columns } = useMicroservicesMarketplaceBuildTable()

  return (
    <PaginatedTable
      tableId="microservices-marketplace"
      paginatedData={paginatedMicroservices}
      onRefresh={async () => {
        await microservicesRefetch()
      }}
      locale={i18n.language}
      columns={columns}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      commands={commands}
      isFetching={isFetching}
      emptyInitialLoadContent={
        <div style={{ marginTop: 100 }}>
          <EmptyStatus message={t('MARKETPLACE.EMPTY_STATUS_MESSAGE')} />
        </div>
      }
      enableVisibilityToggler
      itemCard={(item: MarketplaceMicroserviceView) => (
        <MicroservicesCard
          id={item.id}
          name={item.name}
          description={item.description}
          category={item.category}
          iconName={item.iconName}
          iconBackground={item.iconBackground}
          active={item.active}
          onClick={(id: string) => {
            navigate(`/microservices/${id}`, {
              state: { fromMarketplace: true },
            })
          }}
        />
      )}
    />
  )
}
