import { useTranslation } from 'react-i18next'
import { Workspace } from '../../../query-client/types'
import { EmptyStatus, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useWorkspacesBuildTable } from './useWorkspacesBuildTable'
import { useWorkspacesFilters } from './useWorkspacesFilters'
import { usePaginatedWorkspacesQuery } from './usePaginatedWorkspacesQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import useFetchWorkspacesByBusiness from 'app/hooks/workspace/useFetchWorkspacesByBusiness'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import { useWorkspacesBuildExport } from './useWorkspacesBuildExport'
import { useWorkspacesActions } from './useWorkspacesActions'

interface Props {
  organizationId: string
}

export const WorkspacesFeature = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('workspace_groups')

  // Request data
  const { filterFields } = useWorkspacesFilters({ t })
  const { query, queryName } = usePaginatedWorkspacesQuery()
  const {
    data: paginatedWorkspaces,
    isFetching,
    refetch: workspacesRefetch,
  } = usePaginatedData<Workspace,{ withDelegatedOrganizations: boolean }>(query, queryName, filterFields, {
    organizationId,
  })

  // Export
  const { exportColumns } = useWorkspacesBuildExport(t, paginatedWorkspaces?.withDelegatedOrganizations)
  const { refetch: fetchExportableWorkspaces } = useFetchWorkspacesByBusiness(organizationId)
  const { onExportClicked } = useTableExport({
    columns: exportColumns,
    query: fetchExportableWorkspaces,
    fileName: t('TITLE_WORKSPACES'),
  })

  // Actions and columns
  const { commands } = useWorkspacesActions({ t, onExportClicked })
  const { columns } = useWorkspacesBuildTable({ t, showTenant: paginatedWorkspaces?.withDelegatedOrganizations })

  return (
    <PaginatedTable
      tableId="workspaces-table"
      locale={i18n.language}
      columns={columns}
      paginatedData={paginatedWorkspaces}
      onRefresh={async () => {
        await workspacesRefetch()
      }}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      commands={commands}
      isFetching={isFetching}
      emptyInitialLoadContent={
        <div style={{ marginTop: 100 }}>
          <EmptyStatus message={t('EMPTY_STATUS_WORKSPACES')} />
        </div>
      }
    />
  )
}
