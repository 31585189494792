import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'react-i18next'

type Props = {
	t: TFunction
}

export const useWorkspacesFilters = ({ t }: Props) => {
	useLocalStorageSearchParams()

	const filterFields: FilterField[] = [
		{
			field: 'fqdn',
			text: t('TABLE.FQDN'),
			type: FieldTypes.STRING,
		},
		{
			field: 'ipAddress',
			text: t('TABLE.IP_ADDRESS'),
			type: FieldTypes.STRING,
		},
		{
			field: 'name',
			text: t('TABLE.NAME'),
			type: FieldTypes.STRING,
		},
		{
			field: 'uCoresCpu',
			text: t('TABLE.U_CORES_CPU'),
			type: FieldTypes.STRING,
		},
		{
			field: 'uLastUser',
			text: t('TABLE.U_LAST_USER'),
			type: FieldTypes.STRING,
		},
		{
			field: 'uOperativeSystem',
			text: t('TABLE.U_OPERATIVE_SYSTEM'),
			type: FieldTypes.STRING,
		},
		{
			field: 'uRam',
			text: t('TABLE.U_RAM'),
			type: FieldTypes.STRING,
		},
		{
			field: 'uType',
			text: t('TABLE.U_TYPE'),
			type: FieldTypes.STRING,
		}
	]

	return {
		filterFields,
	}
}
