import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { MicroserviceType, microserviceTypeLabel } from 'app/models/microservices/MicroserviceType'
import { TFunction } from 'i18next'

export const useMicroservicesDesignerFilters = ({
  t,
  canSeeNonStandardMicroservices,
}: {
  t: TFunction
  canSeeNonStandardMicroservices: boolean | undefined
}) => {
  useLocalStorageSearchParams()

  const filterFields: FilterField[] = [
    {
      field: 'nameText',
      text: t('DESIGNER.TABLE.NAME'),
      type: FieldTypes.STRING,
    },
    {
      field: 'categoryText',
      text: t('MARKETPLACE.TABLE.CATEGORY'),
      type: FieldTypes.STRING,
    },
    {
      field: 'organizationName',
      text: t('MARKETPLACE.TABLE.LIBRARY'),
      type: FieldTypes.STRING,
    },
    ...(canSeeNonStandardMicroservices
      ? [
          {
            field: 'type',
            text: t('DESIGNER.TABLE.TYPE'),
            type: FieldTypes.ENUM,
            options: Object.values(MicroserviceType).map((type) => {
              return {
                key: type,
                text: microserviceTypeLabel(t)[type],
              }
            }),
          },
        ]
      : []),
    {
      field: 'archived',
      text: t('DESIGNER.TABLE.ARCHIVED'),
      type: FieldTypes.ENUM,
      options: [
        { key: 'boolean_true', text: t('DESIGNER.ARCHIVED') },
        { key: 'boolean_false', text: t('DESIGNER.UNARCHIVED') },
      ],
    },
  ]

  return {
    filterFields,
  }
}
