import { TFunction } from 'react-i18next'

export const useMicroservicesMarketplaceBuildExport = (t: TFunction) => {
  const exportColumns = [
    {
      key: 'name',
      header: t('MARKETPLACE.TABLE.NAME'),
      width: 40,
    },
    {
      key: 'category',
      header: t('MARKETPLACE.TABLE.CATEGORY'),
      width: 20,
    },
    {
      key: 'organizationName',
      header: t('MARKETPLACE.TABLE.LIBRARY'),
      width: 30,
    },
    {
      key: 'active',
      header: t('MICROSERVICE_STATUS.ACTIVE'),
      width: 20,
    },
  ]

  return { exportColumns }
}
