import { gql } from 'graphql-request'
import { PaginationArgs, PaginationResponse, Workspace } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getWorkspacesByBusinessQuery = gql`
  query ($businessId: String!, $page: Int!, $perPage: Int!, $searchTerm: String!) {
    getWorkspacesByBusiness(businessId: $businessId, page: $page, perPage: $perPage, searchTerm: $searchTerm) {
      workspaces {
        _id
        name
        fqdn
        company
        sysDomain
        sysId
        uLastUser
        uOperativeSystem
        uRelatedEnvironment
        uHostname
        ipAddress
        uCoresCpu
        uRam
        uType
        organizationName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
    }
  }
`

function getWorkspacesByBusiness(
  businessId: string,
  searchTerm: string,
  pagination?: PaginationArgs
): Promise<PaginationResponse<Workspace & { organizationName: string }>> {
  return graphClient
    .request(getWorkspacesByBusinessQuery, {
      businessId,
      page: pagination?.page,
      perPage: pagination?.perPage,
      searchTerm,
    })
    .then((response) => {
      return {
        results: response.getWorkspacesByBusiness.workspaces,
        pageInfo: response.getWorkspacesByBusiness.pageInfo,
      }
    })
}

export default function (businessId: string, searchTerm: string, pagination?: PaginationArgs, enabled: boolean = true) {
  return useQuery(
    ['getWorkspacesByBusiness', pagination, searchTerm],
    () => getWorkspacesByBusiness(businessId, searchTerm, pagination),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
    }
  )
}
