import { EmptyStatus, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { MicroserviceEvent } from 'app/models/microservices'
import { useMicroservicesAuditLogFilters } from './useMicroservicesAuditLogFilters'
import { useMicroservicesAuditLogQuery } from './useMicroservicesAuditLogQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { getLanguageCode, LanguageKey } from 'app/i18n/languages'
import { useMicroservicesAuditLogBuildTable } from './useMicroservicesAuditLogBuildTable'
import { useGetMicroserviceEvents } from 'app/hooks/microservices/useGetMicroserviceEvents'
import { useMicroservicesAuditLogBuildExport } from './useMicroservicesAuditLogBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import { useMicroservicesAuditLogActions } from './useMicroservicesAuditLogActions'

interface Props {
  organizationId: string
}

export const MicroservicesAuditLog = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('microservices')
  const lang = getLanguageCode(i18n.language as LanguageKey)

  // Request data
  const { filterFields } = useMicroservicesAuditLogFilters({ t })
  const { query, queryName } = useMicroservicesAuditLogQuery()
  const {
    data: paginatedLogs,
    isFetching,
    refetch: refetchLogs,
  } = usePaginatedData<MicroserviceEvent>(query, queryName, filterFields, {
    businessId: organizationId,
    language: lang,
  })

  // Export
  const { auditLogTableExportColumns } = useMicroservicesAuditLogBuildExport(t)
  const { refetch: refetchExportLogs } = useGetMicroserviceEvents(organizationId, t)
  const { onExportClicked } = useTableExport({
    query: refetchExportLogs,
    columns: auditLogTableExportColumns,
    fileName: t('AUDIT_LOG.TITLE'),
  })

  // Actions and columns
  const { commandBarActions, onViewDetailsClicked } = useMicroservicesAuditLogActions({ t, onExportClicked })
  const { columns } = useMicroservicesAuditLogBuildTable({ t, i18n, onViewDetailsClicked })

  return (
    <PaginatedTable
      tableId="microservices-audit-log"
      paginatedData={paginatedLogs}
      columns={columns}
      onRefresh={async () => {
        await refetchLogs()
      }}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      commands={commandBarActions}
      isFetching={isFetching}
      emptyInitialLoadContent={<EmptyStatus message={t('AUDIT_LOG.EMPTY_STATUS_MESSAGE')} />}
    />
  )
}
