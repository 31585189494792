import { MOBILE_QUERY, ViewDetailsActionButton, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { ColumnActionsMode } from '@fluentui/react'
import { CellWithGreenLink } from 'app/components/table-page-body/CellWithGreenLink'
import { ProductConfig } from 'app/query-client/types'
import { getProductIcon } from 'app/services/product-icon.service'
import { TFunction, i18n } from 'i18next'
import { useEffect, useState } from 'react'

type UseHomeBuildTableProps = {
  t: TFunction
  i18n: i18n
  openViewProduct: Function
}

export const useHomeBuildTable = ({ t, i18n, openViewProduct }: UseHomeBuildTableProps) => {

  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  useEffect(() => {
    setColumns([
      {
        key: 'environment',
        name: t('PRODUCTS.TABLE.ENVIRONMENT'),
        fieldName: 'environment',
        onRender: (item: ProductConfig) => {
          return <CellWithGreenLink callback={() => openViewProduct(item)}>{item.environment}</CellWithGreenLink>
        },
      },
      {
        key: 'product_item.name',
        name: t('PRODUCTS.TABLE.PRODUCT_TYPE'),
        fieldName: 'product',
        onRender: (item: ProductConfig) => {
          return (
            <span className="d-flex d-flexAlignItemsCenter">
              <span className="d-flex d-flexAlignItemsCenter" style={{ marginRight: 5 }}>
                {getProductIcon(item.product.name, 18, 18)}
              </span>
              <span>{item.product.name}</span>
            </span>
          )
        },
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'actions',
        name: t('PRODUCTS.TABLE.ACTION'),
        onRender: (item: ProductConfig) => {
          return (
            <ViewDetailsActionButton locale={i18n.language} isMobile={isMobile} onClick={() => openViewProduct(item)} />
          )
        },
        columnActionsMode: ColumnActionsMode.disabled,
      },
    ])
  }, [])

  return { columns }
}
