import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateMicroserviceConditionRequest {
  valueType: string
  environment: string
}

const mutation = gql`
  mutation ($input: UpdateConditionMicroserviceGraphqlRequest!, $id: ID!) {
    updateConditionMicroservice(id: $id, input: $input)
  }
`

async function updateMicroserviceCondition(id: string, input: UpdateMicroserviceConditionRequest) {
  await graphClient.request(mutation, { id, input })

  return id
}

export const useUpdateMicroserviceCondition = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateMicroserviceCondition'],
    ({ id, input }: { id: string; input: UpdateMicroserviceConditionRequest }) =>
      updateMicroserviceCondition(id, input),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['microservices'])
        queryClient.invalidateQueries(['microservices', id])
        queryClient.invalidateQueries(['conditions'])
        queryClient.invalidateQueries(['getPaginatedDesignerMicroservices'])
      },
    }
  )
}
