import useCreateForm from 'app/hooks/useCreateForm'
import { TFunction } from 'react-i18next'
import { TenantForm } from './TenantForm'
import { Tenant } from 'app/query-client/types'
import { TenantEditForm } from './TenantEditForm'
import { BusinessType } from 'app/models/business/BusinessType'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { useEffect, useMemo, useState } from 'react'
import useOrganizationExistsOnBuild from '../../../hooks/organization/useOrganizationExistsOnBuild'
import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { useParams } from 'react-router-dom'
import useOrganizationById from '../../../hooks/organization/useOrganizationById'

type UseOrgTenantsActionsProps = {
  t: TFunction
  organizationId: string
  onExportClicked: () => void
}

export const useOrgTenantsActions = ({ t, organizationId, onExportClicked }: UseOrgTenantsActionsProps) => {
  const { checkFeature } = useFeatureRender()
  const { organizationId: organizationIdUrl } = useParams()
  const { data: organizationExistOnBuild } = useOrganizationExistsOnBuild(organizationIdUrl || '')
  const { data: selectedOrganization } = useOrganizationById(organizationIdUrl || '')

  const [canCreate, setCanCreate] = useState<boolean>(false)

  useEffect(() => {
    setCanCreate(false)
    if (!organizationExistOnBuild) {
      return
    }
    if (selectedOrganization?.type === BusinessType.PARTNER || selectedOrganization?.type === BusinessType.WHOLESALER) {
      if (Boolean(selectedOrganization?.elegibleProducts?.length)) {
        setCanCreate(true)
      }
    } else {
      if (
        selectedOrganization?.partner?.type === BusinessType.PARTNER ||
        selectedOrganization?.partner?.type === BusinessType.WHOLESALER
      ) {
        setCanCreate(true)
      }
    }
  }, [selectedOrganization, organizationExistOnBuild])

  const openAddTenant = useCreateForm(
    {
      title: t('TENANTS.FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <TenantForm
      elegibleProducts={selectedOrganization?.elegibleProducts ?? []}
      policies={selectedOrganization?.policies ?? []}
      organizationId={organizationId}
      productConfigs={selectedOrganization?.productConfigs}
      organizationType={selectedOrganization?.type}
    />
  )

  const openEditTenant = (tenant: Tenant) => {
    return useCreateForm(
      {
        title: t('TENANTS.FORM.EDIT_TITLE'),
        isFooterAtBottom: true,
      },
      <TenantEditForm
        tenantId={tenant._id}
        policies={selectedOrganization?.policies ?? []}
        isDefaultTenant={tenant.organizationRelated._id === organizationId}
        data={{
          name: tenant.name,
          product: tenant.product?._id,
          region: tenant.region,
          policyId: tenant.policy?._id,
        }}
      />
    )
  }

  const commandBarActions = useMemo<ICommandItem[]>(
    () =>
      checkFeature(FEATURE_NAMES.TENANT_CREATE)
        ? [
            {
              key: 'create',
              text: t('general:BUTTON.CREATE'),
              iconProps: { iconName: 'Add' },
              onClick: openAddTenant,
              disabled: !canCreate,
            },
            {
              key: 'export',
              text: t('general:BUTTON.EXPORT'),
              iconProps: { iconName: 'ExcelDocument' },
              onClick: onExportClicked,
            },
          ]
        : [],
    [canCreate]
  )

  return {
    openAddTenant,
    openEditTenant,
    commandBarActions,
    canCreate,
  }
}
