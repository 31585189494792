import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { useGetEnabledMicroservices } from '../../../hooks/microservices/useGetEnabledMicroservices'
import { useEnabledMicroservicesBuildTable } from './useEnabledMicroservicesBuildTable'
import { usePaginatedEnabledMicroservicesQuery } from './usePaginatedEnabledMicroservicesQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import i18next from 'i18next'
import { useEnabledMicroservicesFilters } from './useEnabledMicroservicesFilters'
import { useEnabledMicroservicesActions } from './useEnabledMicroservicesActions'
import { PaginatedEnabledMicroservicePageViewData } from './PaginatedEnabledMicroservicePageViewData'
import { renderMicroserviceBlock } from './renderers/renderMicroserviceBlock'
import { EmptyInitialLoadContent } from './renderers/EmptyInitialContent'
import { useEnabledMicroservicesBuildExport } from './useEnabledMicroservicesBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import { getLanguageCode } from '../../../i18n/languages'

export const EnabledMicroservices = ({ organizationId }: { organizationId?: string }) => {
  const { t, i18n } = useTranslation('microservices')

  // Request data
  const { filterFields } = useEnabledMicroservicesFilters({ t })
  const { query, queryName } = usePaginatedEnabledMicroservicesQuery()
  const {
    data: paginatedEnabledMicroservices,
    isFetching,
    refetch: refetchMicroservices,
  } = usePaginatedData<PaginatedEnabledMicroservicePageViewData>(query, queryName, filterFields, {
    organizationId,
    language: getLanguageCode(i18next.language),
  })

  // Export
  const { refetch: refetchEnabledMicroservices } = useGetEnabledMicroservices(organizationId)
  const { exportColumns, exportMapper } = useEnabledMicroservicesBuildExport(t)
  const { onExportClicked } = useTableExport({
    query: refetchEnabledMicroservices,
    columns: exportColumns,
    fileName: t('ENABLED_MICROSERVICES.TITLE'),
    exportMapper,
  })

  // Actions and columns
  const { handleOnClickViewDetails, commandBarActions } = useEnabledMicroservicesActions({ t, onExportClicked })
  const { columns } = useEnabledMicroservicesBuildTable({ t, handleOnClickViewDetails })

  return (
    <PaginatedTable
      tableId="enabled-microservices"
      locale={i18n.language}
      paginatedData={paginatedEnabledMicroservices}
      onRefresh={async () => {
        await refetchMicroservices()
      }}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      commands={commandBarActions}
      enableVisibilityToggler
      onRenderItemCard={renderMicroserviceBlock}
      emptyInitialLoadContent={<EmptyInitialLoadContent {...{ t }} />}
      {...{ columns, isFetching }}
    />
  )
}
