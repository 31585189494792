
import { MessageBar } from "@fluentui/react"
import Dialog from "app/components/Dialog/Dialog"
import { DesignerMicroserviceView } from "app/hooks/microservices/useGetDesignerMicroservices"
import { TFunction } from "i18next"
import { ReactI18NextChild } from "react-i18next"


type unarchiveConfirmationModalProps = {
  microservice: DesignerMicroserviceView,
  t: TFunction,
  isOpen: boolean,
  onDismiss: () => void,
  onConfirm: (item: DesignerMicroserviceView) => void
}

export const UnarchiveConfirmationDialog = ({ microservice, t, isOpen, onDismiss, onConfirm }: unarchiveConfirmationModalProps) => {
  return (
    <Dialog
      title={t('DESIGNER.UNARCHIVE_DIALOG.TITLE')}
      description={t('DESIGNER.UNARCHIVE_DIALOG.DESCRIPTION')}
      actionButton={t('DESIGNER.UNARCHIVE_DIALOG.BUTTON_ACCEPT')}
      dismissButton={t('DESIGNER.UNARCHIVE_DIALOG.BUTTON_CANCEL')}
      hidden={!isOpen}
      onDismiss={onDismiss}
      callback={() => {
        onConfirm(microservice);
      }}
    >
      <MessageBar
        messageBarType={3}
        isMultiline={true}
        dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
        className="mb-2"
        style={{ width: '100%' }}
      >
        {t('DESIGNER.UNARCHIVE_DIALOG.WARNING') as ReactI18NextChild}
      </MessageBar>
    </Dialog>
  )
}