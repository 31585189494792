import { MOBILE_QUERY, useMediaQuery, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'
import { getTranslation, label, labelStatus, MicroserviceEvent } from 'app/models/microservices'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
  i18n: i18n
  onViewDetailsClicked: (microserviceEvent: MicroserviceEvent) => void
}

export const useMicroservicesAuditLogBuildTable = ({ t, i18n, onViewDetailsClicked }: Props) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  useEffect(() => {
    setColumns([
      {
        key: 'microserviceName',
        name: t('AUDIT_LOG.TABLE.MICROSERVICE'),
        fieldName: 'microservice',
        flexGrow: 2,
        isRowHeader: true,
        onRender: (item: MicroserviceEvent) => {
          const name = getTranslation(item.microservice.name, i18n.language)
          const itemName = name.text
          const showNameIcon = name.translatedByIA

          return (
            <CellWithFeatureGreenLink
              className="d-flex d-flexAlignItemsCenter"
              feature={FEATURE_NAMES.MICROSERVICES_READ}
              callback={() => onViewDetailsClicked(item)}
            >
              {itemName}
              {showNameIcon && <IconLanguage tam="16" />}
            </CellWithFeatureGreenLink>
          )
        },
      },
      {
        key: 'type',
        name: t('AUDIT_LOG.TABLE.TYPE'),
        fieldName: 'type',
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: MicroserviceEvent) => <span>{label(t)[item.type]}</span>,
      },
      {
        key: 'status',
        name: t('AUDIT_LOG.TABLE.STATUS'),
        fieldName: 'status',
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: MicroserviceEvent) => <span>{labelStatus(t, item.status ?? '')}</span>,
      },
      {
        key: 'initiatedBy',
        name: t('AUDIT_LOG.TABLE.INITIATED_BY'),
        fieldName: 'initiatedBy',
        flexGrow: 1,
        isRowHeader: true,
      },
      {
        key: 'loggedAt',
        name: t('AUDIT_LOG.TABLE.LOGGED_AT'),
        fieldName: 'loggedAt',
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: MicroserviceEvent) => (
          <span>{dateFormatter(t, new Date(item.loggedAt), DateFormat.DATETIME)}</span>
        ),
      },
      {
        key: 'actions',
        name: t('AUDIT_LOG.TABLE.ACTIONS'),
        flexGrow: 1,
        isPadded: true,
        onRender: (item: MicroserviceEvent) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
              }}
            >
              <ViewDetailsActionButton
                locale={i18n.language}
                isMobile={isMobile}
                onClick={() => onViewDetailsClicked(item)}
              />
            </div>
          )
        },
      },
    ])
  }, [isMobile])

  return {
    columns,
  }
}
