import { MOBILE_QUERY, TABLET_QUERY, useMediaQuery } from "@flexxibleit/flexxible-ui";
import FlexxibleCard from "app/components/FlexxibleCard/FlexxibleCard.component";
import { Module } from "app/query-client/types";
import { getModuleIcon } from "app/services/module-icon.service";

export const H1Card = ({ module, index, getModuleType, onClick }: { module: Partial<Module>; index: number, getModuleType: Function, onClick: Function }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)

  const cardProps = {
    style: isMobile
      ? { width: '100%' }
      : isTablet
        ? { width: 'calc(28% - 10px' }
        : { width: 'calc(20% - 50px)', minWidth: 152 },
    onClick: () => onClick(module.url),
    key: `${index}`,
  }

  return (
    <>
      <FlexxibleCard key={index} styleNames="d-flex d-flexCol d-flexJustifyCenter cursor-pointer" cardProps={cardProps}>
        <div className="d-flex d-center d-flexCol modules__card-link__container">
          <div>{getModuleIcon(module.type || '', 38, 38)}</div>
          <div className="modules__card-link__type">
            {module.type === 'custom' ? module.name : getModuleType(module.type || '')}
          </div>
          <div className="modules__card-link__tag">{module.tag}</div>
        </div>
      </FlexxibleCard>
    </>
  )
}