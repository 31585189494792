import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { Module, ProductConfig } from '../../../query-client/types'
import { Stack, Text } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import './home.feature.scss'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { Accordion } from 'app/components/accordion/Accordion.component'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query'
import Table from 'app/components/Table/Table.component'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import EmptyStatus from 'app/components/status/empty-status'
import { FEATURE_NAME } from '../../../permissions/FeatureName.enum'
import { useHomeActions } from './useHomeActions'
import { MainModules } from '../components/MainModules.component'
import { SecondaryModules } from '../components/SecondaryModules.component'
import { EmptyProducts } from '../components/EmptyProducts.component'
import { useHomeBuildTable } from './useHomeBuildTable'
import { usePaginatedProductsConfigQuery } from 'app/pages/organization-details/products/usePaginatedProductsConfigQuery'
import { useHomeFilters } from './useHomeFilters'
import { useHomeBuildExport } from './useHomeBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import usePaginatedDataAndExport from 'app/hooks/usePaginatedDataAndExport'

interface Props {
  modules: Module[]
  selectedOrganizationId: string
  canSeeDownload: boolean
}

const Home = ({ modules, selectedOrganizationId, canSeeDownload }: Props) => {
  const { t, i18n } = useTranslation('organization_details')

  // Request data
  const { filterFields } = useHomeFilters({ t })
  const { query, queryName } = usePaginatedProductsConfigQuery()
  const { paginatedQuery, exportQuery } = usePaginatedDataAndExport<ProductConfig>(query, queryName, filterFields, {
    businessId: selectedOrganizationId,
    filters: { active: 'true' },
  })

  // Export
  const { homeTableExportColumns, exportMapper } = useHomeBuildExport(t)
  const { onExportClicked } = useTableExport({
    query: exportQuery.refetch,
    columns: homeTableExportColumns,
    fileName: t('home:YOUR_PRODUCTS'),
    exportMapper,
  })

  const { openViewProduct, getModuleType, handleClick, handleClickDownload, commandBarActions } = useHomeActions(
    t,
    onExportClicked
  )
  const { columns } = useHomeBuildTable({ t, i18n, openViewProduct })

  return (
    <>
      <MainModules
        {...{
          modules,
          getModuleType,
          handleModuleClick: handleClick,
          handleClickDownload,
          canSeeDownload,
          t,
          selectedOrganizationId,
        }}
      />

      <SecondaryModules
        {...{
          modules,
          t,
          handleModuleClick: handleClick,
          getModuleType,
        }}
      />

      <Stack style={{ paddingTop: 20, marginBottom: 20 }}>
        <Text style={{ fontSize: 15, fontWeight: 600 }}>{t('home:YOUR_PRODUCTS')}</Text>
      </Stack>

      <PaginatedTable
        tableId="home"
        paginatedData={paginatedQuery.data}
        isFetching={paginatedQuery.isFetching}
        filters={{
          filterFields,
          smartSearch: true,
          advancedSearch: true,
        }}
        emptyInitialLoadContent={<EmptyProducts {...{ t }} />}
        commands={commandBarActions}
        {...{ columns }}
      />
    </>
  )
}

export const HomeFeature = withFeatureRender(Home, FEATURE_NAMES.HOME_READ)
