import { TFunction } from 'react-i18next'

export const useMicroservicesAuditLogBuildExport = (t: TFunction) => {
  const auditLogTableExportColumns = [
    {
      key: 'microserviceName',
      header: t('AUDIT_LOG.TABLE.MICROSERVICE'),
      width: 30,
    },
    {
      key: 'type',
      header: t('AUDIT_LOG.TABLE.TYPE'),
      width: 20,
    },
    {
      key: 'status',
      header: t('AUDIT_LOG.TABLE.STATUS'),
      width: 20,
    },
    {
      key: 'initiatedBy',
      header: t('AUDIT_LOG.TABLE.INITIATED_BY'),
      width: 30,
    },
    {
      key: 'loggedAt',
      header: t('AUDIT_LOG.TABLE.LOGGED_AT'),
      width: 20,
    },
  ]

  return { auditLogTableExportColumns }
}
