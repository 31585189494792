import { EmptyStatus, PaginatedTable } from '@flexxibleit/flexxible-ui'
import usePaginatedData from '../../../hooks/usePaginatedData'
import { usePaginatedInstalledAppsQuery } from './usePaginatedInstalledAppsQuery'
import { useTranslation } from 'react-i18next'
import { useBuildInstalledAppsTable } from './useBuildInstalledAppsTable'
import { useInstalledAppsFilter } from './useInstalledAppsFilter'
import { useInstalledAppsBuildExport } from './useInstalledAppsBuildExport'
import { useInstalledAppsTableExport } from './useInstalledAppsTableExport'
import { useInstalledApps } from './useInstalledApps'
import { useInstalledAppsActions } from './useInstalledAppsActions'
import { InstalledApp } from './InstalledApp'

interface Props {
  organizationId: string
}

export const ListInstalledApps = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('analyzer')

  const { filterFields } = useInstalledAppsFilter({ t })
  const { query, queryName } = usePaginatedInstalledAppsQuery()
  const {
    data: paginatedInstalledApps,
    isFetching,
    refetch: installedAppsRefetch,
  } = usePaginatedData<InstalledApp>(query, queryName, filterFields, {
    businessId: organizationId,
  })

  const { refetch: refetchExportInstalledApps } = useInstalledApps(organizationId)
  const { installedAppsTableExportColumns } = useInstalledAppsBuildExport({ t })
  const { onExportClicked } = useInstalledAppsTableExport({
    query: refetchExportInstalledApps,
    columns: installedAppsTableExportColumns,
    fileName: 'Installed apps',
  })

  const { commandBarActions, openInstalledAppDetails } = useInstalledAppsActions({ t, onExportClicked })
  const { columns } = useBuildInstalledAppsTable({ t, openInstalledAppDetails })

  return (
    <PaginatedTable
      tableId="installed-apps"
      paginatedData={paginatedInstalledApps}
      columns={columns}
      onRefresh={async () => {
        await installedAppsRefetch()
      }}
      filters={{
        filterFields: filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      emptyInitialLoadContent={
        <div style={{ marginTop: 100 }}>
          <EmptyStatus message={t('INSTALLED_APPS.TABLE.EMPTY_MESSAGE')} />
        </div>
      }
      isFetching={isFetching}
      commands={commandBarActions}
      locale={i18n.language}
    />
  )
}
