import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
}

export const useFlexxBuildOperationsLogFilters = ({ t }: Props) => {
  useLocalStorageSearchParams()

  const filterFields: FilterField[] = [
    {
      field: 'businessName',
      text: t('AUDIT_LOG.TABLE.BUSINESS', 'Business'),
      type: FieldTypes.STRING,
    },
    {
      field: 'flexxBuildProcessId',
      text: t('AUDIT_LOG.TABLE.FLEXX_BUILD_PROCESS_ID', 'Flexx Build Process ID'),
      type: FieldTypes.STRING,
    },
    {
      field: 'processStatus',
      text: t('AUDIT_LOG.TABLE.PROCESS_STATUS', 'Process Status'),
      type: FieldTypes.STRING,
    },
    {
      field: 'processName',
      text: t('AUDIT_LOG.TABLE.PROCESS_NAME', 'Process Name'),
      type: FieldTypes.STRING,
    },
    {
      field: 'error',
      text: t('AUDIT_LOG.TABLE.ERROR', 'Error'),
      type: FieldTypes.STRING,
    },
    {
      field: 'initiatorFeature',
      text: t('AUDIT_LOG.TABLE.INITIATOR_FEATURE', 'Initiator Feature'),
      type: FieldTypes.STRING,
    },
  ]

  return { filterFields }
}
