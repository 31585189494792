import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const query = gql`
  query Request {
    findAllFlexxBuildProcessForExport {
      _id
      business {
        _id
        name
      }
      flexxBuildProcessId
      processStatus
      processName
      error
      initiatorFeature
      created_at
      updated_at
    }
  }
`

interface FlexxBuildProcess {
  _id: string
  business: {
    _id: string
    name: string
  }
  businessName: string
  flexxBuildProcessId: string
  processStatus: string
  processName: string
  error: string
  initiatorFeature: string
  created_at: Date
  updated_at: Date
}

async function findAllFlexxBuildProcess() {
  return graphClient.request(query).then((response) => {
    return response.findAllFlexxBuildProcessForExport.map((item: FlexxBuildProcess) => {
      return {
        ...item,
        businessName: item.business.name,
        created_at: new Date(item.created_at),
        updated_at: new Date(item.updated_at),
      }
    })
  })
}

export default function () {
  return useQuery(['findAllFlexxBuildProcess'], () => findAllFlexxBuildProcess(), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
