import { BadgeMode, MOBILE_QUERY, StatusBadge, useMediaQuery, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { ColumnActionsMode, Spinner } from '@fluentui/react'
import { Badge } from 'app/components/Badge/Badge'
import { MarketplaceMicroserviceView } from 'app/hooks/microservices/useGetMarketplaceMicroservices'
import { lazy, Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MicroserviceCategory } from '../components/MicroserviceCategory'
import { MicroserviceName } from '../components/MicroserviceName'

const BootstrapIcon = lazy(() => import('app/components/BootstrapIcon/BootstrapIcon'))

export const useMicroservicesMarketplaceBuildTable = () => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t, i18n } = useTranslation('microservices')
  const navigate = useNavigate()
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  const handleOnClickViewDetails = (item: MarketplaceMicroserviceView) => {
    navigate(`/microservices/${item.id}`, {
      state: { fromMarketplace: true },
    })
  }

  useEffect(() => {
    setColumns([
      {
        key: 'iconName',
        name: t('MARKETPLACE.TABLE.ICON'),
        fieldName: 'iconName',
        minWidth: 50,
        maxWidth: 50,
        isResizable: false,
        isSortable: false,
        isRowHeader: true,
        isIconOnly: true,
        onRender: (item: MarketplaceMicroserviceView) => {
          if (!item.iconName) return null

          return (
            <Suspense fallback={<Spinner />}>
              <BootstrapIcon
                iconName={item.iconName}
                size={24}
                style={item.iconBackground ? { color: item.iconBackground } : {}}
              />
            </Suspense>
          )
        },
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'nameText',
        name: t('MARKETPLACE.TABLE.NAME'),
        fieldName: 'name',
        flexGrow: 2,
        isRowHeader: true,
        onRender: (item: MarketplaceMicroserviceView) => (
          <MicroserviceName item={item} onClick={handleOnClickViewDetails} />
        ),
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'categoryText',
        name: t('MARKETPLACE.TABLE.CATEGORY'),
        fieldName: 'category',
        isRowHeader: true,
        onRender: (item: MarketplaceMicroserviceView) => <MicroserviceCategory {...item} />,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'organizationName',
        name: t('MARKETPLACE.TABLE.LIBRARY'),
        fieldName: 'library',
        isRowHeader: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: MarketplaceMicroserviceView) => {
          return (
            <>
              {item.organization.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}
              <span>{item.organization.name}</span>
            </>
          )
        },
      },
      {
        key: 'active',
        name: t('MICROSERVICE_STATUS.ACTIVE'),
        fieldName: 'active',
        isRowHeader: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: MarketplaceMicroserviceView) => {
          return (
            <StatusBadge
              active={item.active || false}
              mode={BadgeMode.circle}
              size={8}
              labels={{ active: t('MICROSERVICE_STATUS.ACTIVE'), inactive: t('MICROSERVICE_STATUS.INACTIVE') }}
            />
          )
        },
      },
      {
        key: 'actions',
        name: t('MARKETPLACE.TABLE.ACTIONS'),

        onRender: (item: MarketplaceMicroserviceView) => {
          return (
            <ViewDetailsActionButton
              locale={i18n.language}
              isMobile={isMobile}
              onClick={() => handleOnClickViewDetails(item)}
            />
          )
        },
        columnActionsMode: ColumnActionsMode.disabled,
      },
    ])
  }, [isMobile])

  return {
    columns,
  }
}
