import { EmptyStatus, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { SelectionMode } from '@fluentui/react'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFlowsBuildTable } from './useFlowsBuildTable'
import { useFlowsActions } from './useFlowsActions'
import { useFlowsBuildExport } from './useFlowsBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import useGetFlows, { FlowListItem } from 'app/hooks/flows/useGetFlows'
import { usePaginatedFlowsQuery } from './usePaginatedFlowsQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { getLanguageCode, LanguageKey } from 'app/i18n/languages'
import { useFlowsFilters } from './useFlowsFilters'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'

interface Props {
  organizationId: string
}

export const FlowsFeature = ({ organizationId }: Props) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('flows')
  const lang = getLanguageCode(i18n.language as LanguageKey)

  const { checkFeature } = useFeatureRender()

  // Request data
  const { filterFields } = useFlowsFilters({ t })
  const { query, queryName } = usePaginatedFlowsQuery()
  const {
    data: paginatedFlows,
    isFetching,
    refetch: refetchFlows,
  } = usePaginatedData<FlowListItem>(query, queryName, filterFields, {
    organizationId,
    language: lang,
  })

  // Export
  const { flowTableExportColumns } = useFlowsBuildExport(t)
  const { refetch: refetchExportFlows } = useGetFlows(organizationId, t)
  const { onExportClicked } = useTableExport({
    query: refetchExportFlows,
    columns: flowTableExportColumns,
    fileName: t('TITLE'),
  })

  // Actions and columns
  const { commandBarActions, onGoToDetail } = useFlowsActions({
    t,
    onExportClicked,
  })
  const { columns } = useFlowsBuildTable({ t, i18n, onGoToDetail })

  return (
    <PaginatedTable
      tableId="flows"
      locale={i18n.language}
      paginatedData={paginatedFlows}
      columns={columns}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      onRefresh={async () => {
        await refetchFlows()
      }}
      commands={commandBarActions}
      isFetching={isFetching}
      emptyInitialLoadContent={
        <div style={{ marginTop: 100 }}>
          <EmptyStatus
            message={t('EMPTY_MESSAGE')}
            buttonProps={
              checkFeature(FEATURE_NAMES.FLOWS_CREATE)
                ? {
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                    onClick: () => {
                      navigate('/flows/create')
                    },
                  }
                : undefined
            }
          />
        </div>
      }
    />
  )
}
