import { ICommandItem } from "@flexxibleit/flexxible-ui"
import { TFunction } from "react-i18next"

type Props = {
	t: TFunction
	onExportClicked: () => void
}

export const useFlexxBuildOperationsLogActions = ({ t, onExportClicked }: Props) => {

	const commandBarActions: ICommandItem[] = [
		{
			key: 'export',
			text: t('general:BUTTON.EXPORT'),
			iconProps: { iconName: 'ExcelDocument' },
			onClick: onExportClicked,
		}
	]

	return {
		commandBarActions
	}
}