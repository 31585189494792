import { pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedWorkspacesQuery = () => {
  const queryName = 'getPaginatedWorkspacesByBusiness'

  const query = gql`
    query Request(
      $organizationId: ID!,
      $searchTerm: String!,
      $page: Int!,
      $perPage: Int!,
      $sorting: GetPaginatedWorkspacesByBusinessGraphqlSort!,
      $filters: GetPaginatedWorkspacesByBusinessGraphqlFilters!
    ) {
      ${queryName}(
        organizationId: $organizationId,
        ${paginatedVarsBlock}
      ) {
        withDelegatedOrganizations
        data {
          _id
          name
          fqdn
          ipAddress
          uOperativeSystem
          uCoresCpu
          uRam
          uType
          uLastUser
          organizationName
        }
        ${pageInfoBlock}
      }
    }
  `

  return {
    query,
    queryName,
  }
}
