import { FlexxibleSecondaryButton, LoadingSpinner, SearchBox } from '@flexxibleit/flexxible-ui'
import { Business, PaginationArgs, User, Workspace } from '../../../query-client/types'
import { ChangeEvent, useMemo, useState } from 'react'
import useGetWorkspacesByWorkspaceGroupId from '../../../hooks/workspaceGroup/useGetWorkspacesByWorkspaceGroupId'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, Stack } from '@fluentui/react'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import EditButton from '../../../components/Buttons/EditButton.component'
import Table from '../../../components/Table/Table.component'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import useCreateForm from 'app/hooks/useCreateForm'
import ImportWorkspacesForm from './ImportWorkspacesForm'
import { WorkspaceGroupType } from 'app/models/workspaces-groups/WorkspaceGroupType'
import { FeatureRender } from '../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

interface Props {
  workspaceGroupId: string
  type?: WorkspaceGroupType
  organization: Business
  onEnterEditMode: () => void
  getColumns: () => IColumn[]
}

export const GroupedWorkspacesReadMode = (props: Props) => {
  const { t } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const [searchBoxValueState, setSearchBoxValueState] = useState<string>('')

  const [pagination, setPagination] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 10,
  }))
  const { data, isLoading, isFetching } = useGetWorkspacesByWorkspaceGroupId(
    props.workspaceGroupId,
    searchBoxValueState,
    pagination
  )

  const workspacesPage: Workspace[] = useMemo(() => data?.results || [], [data])
  const columns = useMemo(() => {
    if (data?.results?.[0]?.organizationName) {
      return [
        {
          key: 'organizationName',
          name: t('workspace_groups:TABLE.TENANT_NAME'),
          fieldName: 'organizationName',
          isRowHeader: true,
          minWidth: 100,
          isPadded: true,
          columnActionsMode: ColumnActionsMode.disabled,
        },
        ...props.getColumns(),
      ]
    }
    return props.getColumns()
  }, [data])

  const handleOnSearchBoxChange = _.debounce((e?: ChangeEvent, value?: string) => {
    setSearchBoxValueState(value || '')
  }, 500)

  const handleOnPaginationChange = (pagination: PaginationArgs) => {
    setPagination(pagination)
  }

  const openImportWorkspacesForm = useCreateForm(
    {
      title: t('WORKSPACE_GROUPS.IMPORT_WORKSPACES'),
      isFooterAtBottom: true,
    },
    <ImportWorkspacesForm workspaceGroupId={props.workspaceGroupId} />
  )

  const renderReadMode = () => {
    if (isLoading) {
      return <LoadingSpinner />
    }

    return (
      <>
        <Stack
          horizontal={!isMobile}
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ padding: '20px 0 0 0' }}
          className="mb-sm-2"
          style={{ minHeight: '70px' }}
        >
          <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
            <SearchBox placeholder={t('WORKSPACE_GROUPS.SEARCH_PLACEHOLDER')} onChange={handleOnSearchBoxChange} />
          </Stack.Item>

          {props.type !== WorkspaceGroupType.DYNAMIC && (
            <FeatureRender feature={FEATURE_NAMES.WORKSPACE_GROUPS_UPDATE}>
              <Stack.Item>
                <div
                  className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyEnd' : ''} `}
                  style={isMobile ? { marginTop: 15 } : {}}
                >
                  <FlexxibleSecondaryButton
                    onClick={openImportWorkspacesForm}
                    className="mr-2"
                    text={t('WORKSPACE_GROUPS.IMPORT_WORKSPACES')}
                  />
                  <EditButton onClick={() => props.onEnterEditMode()} />
                </div>
              </Stack.Item>
            </FeatureRender>
          )}
        </Stack>

        <Table
          tableId="workspaces-read-mode"
          isLoading={isLoading}
          isEmpty={workspacesPage.length === 0}
          emptyMessage={t('general:EMPTY_FILTER')}
          tableProps={{
            columns,
            items: workspacesPage,
            compact: false,
            selectionMode: SelectionMode.none,
            layoutMode: DetailsListLayoutMode.justified,
            isHeaderVisible: true,
            onShouldVirtualize: () => false,
          }}
          paginationProps={
            data
              ? {
                  pagination,
                  onPaginationChange: handleOnPaginationChange,
                  paginatedData: data,
                  isFetching,
                }
              : undefined
          }
        />
      </>
    )
  }

  return renderReadMode()
}
