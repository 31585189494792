import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { SelectionMode } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { FlexxBuildProcess, useFlexxBuildOperationsLogBuildTable } from './useFlexxBuildOperationsLogBuildTable'
import { useFlexxBuildOperationsLogActions } from './useFlexxBuildOperationsLogActions'
import { useFlexxBuildOperationsLogBuildExport } from './useFlexxBuildOperationsLogBuildExport'
import { useFlexxBuildOperationsLogFilters } from './useFlexxBuildOperationsLogFilters'
import { usePaginatedFlexxBuildOperationsLogQuery } from './usePaginatedFlexxBuildOperationsLogQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import useFindAllFlexxBuildProcess from 'app/hooks/flexxBuildProcess/useFindAllFlexxBuildProcess'

export const FlexxBuildOperationsLogTable = () => {
  const { t, i18n } = useTranslation('flexxbuild')

  // Request data
  const { filterFields } = useFlexxBuildOperationsLogFilters({ t })
  const { query, queryName } = usePaginatedFlexxBuildOperationsLogQuery(
    'FlexxBuildProcessFilterGraphql',
    'FlexxBuildProcessSortingGraphql'
  )
  const { data: paginatedOpLogs, isFetching, refetch: opLogsRefetch } = usePaginatedData<FlexxBuildProcess>(query, queryName, filterFields, {})

  // Export
  const { operationLogsTableExportColumns } = useFlexxBuildOperationsLogBuildExport()
  const { refetch } = useFindAllFlexxBuildProcess()
  const { onExportClicked } = useTableExport({
    query: refetch,
    columns: operationLogsTableExportColumns,
    fileName: 'Operations log',
  })

  // Actions and columns
  const { commandBarActions } = useFlexxBuildOperationsLogActions({ t, onExportClicked })
  const { columns } = useFlexxBuildOperationsLogBuildTable({ t })

  return (
    <PaginatedTable
      tableId="flexxbuild-operations"
      locale={i18n.language}
      paginatedData={paginatedOpLogs}
      columns={columns}
      onRefresh={async () => {
        await opLogsRefetch()
      }}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      commands={commandBarActions}
      isFetching={isFetching}
      selectionMode={SelectionMode.none}
    />
  )
}
