import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const useGetPaginatedDesignerMicroservicesQuery = () => {
  const queryName = 'getPaginatedDesignerMicroservices'
  const query = gql`
query Request(
  $businessId: ID!
  $language: String!
  ${getPaginatedQueryParamsBlock(
    'GetPaginatedDesignerMicroservicesGraphqlFilter',
    'GetPaginatedDesignerMicroservicesGraphqlSort'
  )}
) {
  getPaginatedDesignerMicroservices(
    businessId: $businessId
    language: $language
    ${paginatedVarsBlock}
  ) {
    data {
      id
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      category {
        code
        text
        translatedByIA
      }
      organization {
        name
        isTrial
      }
      createdAt
      archivedAt
      canEdit
      type
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
