import { pageInfoBlock, paginatedVarsBlock } from "app/config/pagination"
import { gql } from "graphql-request"

export const useMicroservicesMarketplaceQuery = () => {
	const queryName = 'getPaginatedMarketplaceList'

	const query = gql`
		query Request(
			$businessId: ID!
			$language: String!
			$page: Int!
			$perPage: Int!
			$searchTerm: String!
			$filters: GetPaginatedMarketplaceGraphqlFilters!
			$sorting: GetPaginatedMarketplaceGraphqlSort!
		) {
			getPaginatedMarketplaceList(
				businessId: $businessId
				language: $language
				${paginatedVarsBlock}
			) {
				data {
					active
					category {
						code
						text
						translatedByIA
					}
					createdAt
					description {
						code
						text
						translatedByIA
					}
					iconBackground
					iconName
					id
					name {
						code
						text
						translatedByIA
					}
					organization {
						isTrial
						name
					}
				}
				${pageInfoBlock}
			}
		}
	`

	return {
		query,
		queryName,
	}
}