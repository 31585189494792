import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { User } from '../../../query-client/types'

import { useTableExport } from 'app/hooks/utils/useTableExport'
import { useUsersActions } from './useUsersActions'
import { useUsersBuildTable } from './useUsersBuildTable'
import { useUsersFilters } from './useUsersFilters'
import { usePaginatedBusinessUsersQuery } from './usePaginatedBusinessUsersQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import useBusinessUsers from 'app/hooks/users/useBusinessUsers'
import { EmptyUsers } from './EmptyUsers'
import { useUsersBuildExport } from './useUsersBuildExport'
import useGetRolesByOrganization from 'app/hooks/roles/useGetRolesByOrganization'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'

interface Props {
  organizationId: string
  roles?: boolean
}

export const UsersFeature = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const { checkFeature } = useFeatureRender()

  // Request data
  const { filterFields } = useUsersFilters({ t })
  const { query } = usePaginatedBusinessUsersQuery()
  const { data: paginatedUsers, isFetching, refetch: refetchUsers } = usePaginatedData<User>(
    query,
    'getPaginatedBusinessUsers',
    filterFields,
    { businessId: organizationId }
  )

  const { data: rolesByOrganization } = useGetRolesByOrganization(organizationId || '')

  // Export
  const canAssignAdminRole = checkFeature(FEATURE_NAMES.USER_ADMIN_ROLE_UPDATE)
  const { refetch: refetchExportUsers } = useBusinessUsers(organizationId)
  const { userTableExportColumns } = useUsersBuildExport(
    canAssignAdminRole ? rolesByOrganization : rolesByOrganization?.filter((role) => !role.isAdminRole)
  )
  const { onExportClicked } = useTableExport({
    query: refetchExportUsers,
    columns: userTableExportColumns,
    fileName: 'Users list',
  })

  // Actions and columns
  const { openUpdateUserForm, onAddUserClicked, commandBarActions } = useUsersActions({
    t,
    organizationId,
    onExportClicked,
  })
  const { columns } = useUsersBuildTable({ t, i18n, openUpdateUserForm, organizationId })

  return (
    <PaginatedTable
      tableId="users"
      locale={i18n.language}
      paginatedData={paginatedUsers}
      onRefresh={async () => {
        await refetchUsers()
      }}
      columns={columns}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      emptyInitialLoadContent={<EmptyUsers {...{ t, onAddUserClicked }} />}
      commands={commandBarActions}
      isFetching={isFetching}
    />
  )
}
