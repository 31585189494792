import { EmptyStatus, MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { TFunction } from 'i18next'

export const EmptyProducts = ({ t }: { t: TFunction }) => {

  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  return (
    <div style={{ marginTop: isMobile ? 40 : 140 }}>
      <EmptyStatus message={t('home:EMPTY_LIST')} />
    </div>
  )
}
