import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { Separator, Stack } from '@fluentui/react'
import { Accordion } from 'app/components/accordion/Accordion.component'
import { Module } from 'app/query-client/types'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { H2Card } from './H2Card.component'

type SecondaryModulesProps = {
  modules: Module[]
  t: TFunction
  handleModuleClick: Function
  getModuleType: Function
}

export const SecondaryModules = ({ modules, t, handleModuleClick, getModuleType }: SecondaryModulesProps) => {

  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const visibility2List = useMemo(
    () => modules.filter((module) => module.visibility === 2).sort((a, b) => a.order - b.order),
    [modules]
  )

  const AccordionHeader = () => {
    return (
      <Stack.Item grow>
        <Separator styles={{ content: { backgroundColor: '#faf9f8', paddingLeft: 0 } }} alignContent="start">
          {t('home:VIEW_MORE') + ' (' + visibility2List.length + ')'}
        </Separator>
      </Stack.Item>
    )
  }

  return (
    visibility2List.length > 0 && (
      <div style={{ marginTop: 41 }}>
        <Accordion headerContent={<AccordionHeader />} opened={false}>
          <Stack tokens={{ childrenGap: 20 }} horizontal style={{ width: '100%', padding: '5px 0 15px' }} wrap>
            {visibility2List.map((module, index) => (
              <Stack.Item
                key={`${index}`}
                style={
                  !isMobile
                    ? { minWidth: 'calc(33.33% - 10px)', maxWidth: 'calc(33.33% - 10px)' }
                    : { minWidth: 'calc(50% - 10px)', maxWidth: 'calc(50% - 10px)' }
                }
              >
                <H2Card onClick={handleModuleClick} {...{ module, index, getModuleType }} />
              </Stack.Item>
            ))}
          </Stack>
        </Accordion>
      </div>
    ) || <></>
  )
}
