import { FieldTypes } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'react-i18next'

type UseHomeFiltersProps = {
  t: TFunction
}

export const useHomeFilters = ({ t }: UseHomeFiltersProps) => {
  useLocalStorageSearchParams()

  const filterFields = [
    {
      field: 'environment',
      text: t('PRODUCTS.TABLE.ENVIRONMENT'),
      type: FieldTypes.STRING,
    },
    {
      field: 'name',
      text: t('PRODUCTS.TABLE.PRODUCT_TYPE'),
      type: FieldTypes.STRING
    }
  ]

  return {
    filterFields
  }
}
