import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'

interface ExportableColumn {
  key: string
  header: string
  width: number
}

export const useWorkspacesBuildExport = (t: TFunction, showTenant?: boolean) => {
  const [exportColumns, setExportColumns] = useState<ExportableColumn[]>([])

  useEffect(() => {
    setExportColumns([
      ...(showTenant
        ? [
            {
              key: 'organizationName',
              header: t('TABLE.TENANT_NAME'),
              width: 40,
            },
          ]
        : []),
      {
        key: 'name',
        header: t('TABLE.NAME'),
        width: 40,
      },
      {
        key: 'fqdn',
        header: t('TABLE.FQDN'),
        width: 50,
      },
      {
        key: 'ipAddress',
        header: t('TABLE.IP_ADDRESS'),
        width: 25,
      },
      {
        key: 'uOperativeSystem',
        header: t('TABLE.U_OPERATIVE_SYSTEM'),
        width: 50,
      },
      {
        key: 'uCoresCpu',
        header: t('TABLE.U_CORES_CPU'),
        width: 15,
      },
      {
        key: 'uRam',
        header: t('TABLE.U_RAM'),
        width: 20,
      },
      {
        key: 'uType',
        header: t('TABLE.U_TYPE'),
        width: 30,
      },
      {
        key: 'uLastUser',
        header: t('TABLE.U_LAST_USER'),
        width: 40,
      },
    ])
  }, [showTenant])

  return { exportColumns }
}
