import { useTranslation } from 'react-i18next'
import { FlexxBuildOperationsLogTable } from './FlexxBuildOperationsLogTable'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from '../../../hooks/utils/useLocalStorageSearchParams'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TitleComponent from '../../../components/PageBody/PageTitle.component'

const FlexxBuildOperationsLog = () => {
  const { t } = useTranslation('flexxbuild')
  const navigate = useNavigate()
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)

  useEffect(() => {
    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}${finalSearchParams}`)
  }, [])

  return (
    <TablePageBody
      title={t('TABLE.TITLE', 'FlexxBuild Operations')}
      titleComponent={<TitleComponent title={t('TABLE.TITLE', 'FlexxBuild Operations')} />}
      isLoading={false}
    >
      <FlexxBuildOperationsLogTable />
    </TablePageBody>
  )
}

export const FlexxBuildOperationsLogPage = withFeatureRender(
  FlexxBuildOperationsLog,
  FEATURE_NAMES.BUILD_OPERATIONS_LOG_READ
)
