import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'

import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { Badge } from 'app/components/Badge/Badge'
import { renderMicroserviceName } from './components/renderMicroserviceName'
import { DesignerMicroserviceView } from 'app/hooks/microservices/useGetDesignerMicroservices'
import { renderMicroserviceCategory } from './components/renderMicroserviceCategory'
import { microserviceTypeLabel } from 'app/models/microservices/MicroserviceType'
import StatusBadge, { BadgeMode } from 'app/components/status-badge/status-badge.component'
import { ContextualMenuButton } from 'app/components/Buttons/ActionButtons/ContextualMenu.component'
import { buildContextActions } from './components/buildContextActions'

type UseEnabledMicroservicesBuildTableProps = {
  t: TFunction
  contextActionsEnabled: boolean
  handleOnClickViewDetails: (item: DesignerMicroserviceView) => void
  onClickEdit: (item: DesignerMicroserviceView) => void
  onClickArchive: (item: DesignerMicroserviceView) => void
  onClickUnarchive: (item: DesignerMicroserviceView) => void
  canSeeNonStandardMicroservices: boolean | undefined
}

export const useMicroservicesDesignerBuildTable = ({
  t,
  contextActionsEnabled,
  handleOnClickViewDetails,
  onClickEdit,
  onClickArchive,
  onClickUnarchive,
  canSeeNonStandardMicroservices,
}: UseEnabledMicroservicesBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  useEffect(() => {
    setColumns([
      {
        key: 'nameText',
        name: t('DESIGNER.TABLE.NAME'),
        fieldName: 'name',
        flexGrow: 3,
        onRender: (item) => renderMicroserviceName(item, handleOnClickViewDetails),
      },
      {
        key: 'categoryText',
        name: t('MARKETPLACE.TABLE.CATEGORY'),
        fieldName: 'category',
        onRender: renderMicroserviceCategory,
      },
      ...(canSeeNonStandardMicroservices
        ? [
            {
              key: 'type',
              name: t('DESIGNER.TABLE.TYPE'),
              fieldName: 'type',
              onRender: (item: DesignerMicroserviceView) => {
                return microserviceTypeLabel(t)[item.type]
              },
            },
          ]
        : []),
      {
        key: 'organizationName',
        name: t('DESIGNER.TABLE.LIBRARY'),
        fieldName: 'library',
        onRender: (item: DesignerMicroserviceView) => {
          return (
            <>
              {item.organization.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}
              <span>{item.organization.name}</span>
            </>
          )
        },
      },
      {
        key: 'archivedAt',
        name: t('DESIGNER.TABLE.ARCHIVED'),
        fieldName: 'archived',
        onRender: (item: DesignerMicroserviceView) => {
          return (
            <StatusBadge
              active={!item.archivedAt}
              mode={BadgeMode.circle}
              size={8}
              labels={{
                active: t('DESIGNER.UNARCHIVED'),
                inactive: t('DESIGNER.ARCHIVED'),
              }}
            />
          )
        },
      },
      {
        key: 'actions',
        name: t('DESIGNER.TABLE.ACTIONS'),
        onRender: (item: DesignerMicroserviceView) => {
          if (contextActionsEnabled) {
            return (
              <ContextualMenuButton
                items={buildContextActions(
                  item,
                  t,
                  handleOnClickViewDetails,
                  onClickEdit,
                  onClickUnarchive,
                  onClickArchive
                )}
              />
            )
          }

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
              }}
            >
              <ViewDetailsActionButton
                locale={i18next.language}
                isMobile={isMobile}
                onClick={() => handleOnClickViewDetails(item)}
              />
            </div>
          )
        },
      },
    ])
  }, [canSeeNonStandardMicroservices])

  return {
    columns,
  }
}
