import { useTranslation } from 'react-i18next'
import { MicroservicesMarketplace } from './MicroservicesMarketplace'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import TitleComponent from 'app/components/PageBody/PageTitle.component'

const MicroservicesMarketplacePageBody = () => {
  const { t } = useTranslation('microservices')
  const navigate = useNavigate()
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)
  const { selectedOrganizationId, selectedOrganization } = useOrganizationSelection()

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}${finalSearchParams}`)
  }, [selectedOrganizationId])

  return (
    <TablePageBody
      isLoading={false}
      title={selectedOrganization?.name || ''}
      titleComponent={
        <TitleComponent
          title={`${selectedOrganization?.name} - ${t('MARKETPLACE.TITLE')}`}
          type={selectedOrganization?.type}
          tag={PRODUCT_ENV !== ProductEnvTypes.FXXONE ? 'PREVIEW' : undefined}
        />
      }
    >
      <MicroservicesMarketplace organizationId={selectedOrganizationId ?? ''} />
    </TablePageBody>
  )
}

export const MicroservicesMarketplacePage = withFeatureRender(
  MicroservicesMarketplacePageBody,
  FEATURE_NAMES.MICROSERVICES_READ
)
