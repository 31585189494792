import { paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedFlexxBuildOperationsLogQuery = (filtersName: string, sortingName: string) => {
  const queryName = 'findAllFlexxBuildProcess'

  const query = gql`
    query Request($page: Int!, $perPage: Int!, $searchTerm: String!, $filters: ${filtersName}!, $sorting: ${sortingName}!) {
      findAllFlexxBuildProcess(${paginatedVarsBlock}) {
        data {
          _id
          business {
            _id
            name
          }
          flexxBuildProcessId
          processStatus
          processName
          error
          initiatorFeature
          created_at
          updated_at
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          totalPages
          currentPage
          total
        }
      }
    }
  `

  return {
    query,
    queryName,
  }
}
