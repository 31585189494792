import { withFeatureRender } from '../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { WorkspacesFeature } from '../organization-details/workspaces/WorkspacesFeature'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import TitleComponent from 'app/components/PageBody/PageTitle.component'

const Workspaces = () => {
  const { selectedOrganizationId, selectedOrganization } = useOrganizationSelection()

  const { t } = useTranslation('workspace_groups')

  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)
  const navigate = useNavigate()
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}/${finalSearchParams}`)
  }, [selectedOrganizationId])

  return (
    <TablePageBody
      title={t('TITLE_WORKSPACES')}
      titleComponent={
        <TitleComponent
          title={`${selectedOrganization?.name} - ${t('TITLE_WORKSPACES')}`}
          type={selectedOrganization?.type}
        />
      }
      isLoading={false}
    >
      {selectedOrganizationId && <WorkspacesFeature organizationId={selectedOrganizationId} />}
    </TablePageBody>
  )
}

export const WorkspacesPage = withFeatureRender(Workspaces, FEATURE_NAMES.WORKSPACES_READ)
