import { useNavigate } from 'react-router'
import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { TFunction } from 'i18next'
import { DesignerMicroserviceView } from 'app/hooks/microservices/useGetDesignerMicroservices'
import { useBoolean } from '@fluentui/react-hooks'
import { useState } from 'react'
import { useArchiveMicroservice } from 'app/hooks/microservices/useArchiveMicroservice'
import { useUnarchiveMicroservice } from 'app/hooks/microservices/useUnarchiveMicroservice'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { useMe } from 'app/hooks/me/useMe'
import { SpecialPermission } from 'app/query-client/types'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

type useMicroservicesDesignerActionsProps = {
  t: TFunction
  onExportClicked: () => void
}

export const useMicroservicesDesignerActions = ({ t, onExportClicked }: useMicroservicesDesignerActionsProps) => {
  const navigate = useNavigate()
  const { rolesOnBusiness } = useOrganizationSelection()
  const [selectedMicroservice, setSelectedMicroservice] = useState<DesignerMicroserviceView | null>(null)
  const [hideArchiveDialog, { toggle: toggleHideArchiveDialog }] = useBoolean(true)
  const [hideUnarchiveDialog, { toggle: toggleHideUnarchiveDialog }] = useBoolean(true)
  const archiveMicroservice = useArchiveMicroservice()
  const unarchiveMicroservice = useUnarchiveMicroservice()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const { data: me } = useMe()

  const canCreateMicroservice = rolesOnBusiness?.hasL2OrHigher()
  const canCreateConditionMicroservice = me && me.specialPermissions?.includes(SpecialPermission.CONDITION_MICROSERVICE)
  const canCreateCustomFieldsMicroservice = me && me.specialPermissions?.includes(
    SpecialPermission.CUSTOM_FIELDS_MICROSERVICE
  )

  const handleOnClickViewDetails = (microservice: DesignerMicroserviceView) => {
    navigate(`/microservices-designer/${microservice.id}`)
  }

  const onClickEdit = (microservice: DesignerMicroserviceView) => navigate(`/microservices-designer/${microservice?.id}/edit`)

  const onClickUnarchive = (microservice: DesignerMicroserviceView) => {
    setSelectedMicroservice(microservice)
    toggleHideUnarchiveDialog()
  }

  const onClickArchive = (microservice: DesignerMicroserviceView) => {
    setSelectedMicroservice(microservice)
    toggleHideArchiveDialog()
  }

  const onConfirmArchive = (microservice: DesignerMicroserviceView) => {
    toggleHideArchiveDialog()
    handleArchive(microservice?.id)
    setSelectedMicroservice(null)
  }

  const onConfirmUnarchive = (microservice: DesignerMicroserviceView) => {
    toggleHideUnarchiveDialog()
    handleUnarchive(microservice?.id)
    setSelectedMicroservice(null)
  }

  const handleArchive = (id?: string) => {
    if (!id) return

    archiveMicroservice
      .mutateAsync(id)
      .then(() => setSuccessMessage(t('DESIGNER.ARCHIVE_DIALOG.SUCCESS')))
      .catch(() => setErrorMessage(t('DESIGNER.ARCHIVE_DIALOG.ERROR')))
  }

  const handleUnarchive = (id?: string) => {
    if (!id) return

    unarchiveMicroservice
      .mutateAsync(id)
      .then(() => setSuccessMessage(t('DESIGNER.UNARCHIVE_DIALOG.SUCCESS')))
      .catch(() => setErrorMessage(t('DESIGNER.UNARCHIVE_DIALOG.ERROR')))
  }

  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
    ...(canCreateMicroservice ? [
      {
        key: 'create',
        text: t('general:BUTTON.CREATE'),
        iconProps: { iconName: 'Add' },
        onClick: () => navigate('/microservices-designer/create'),
      },
      ...(canCreateConditionMicroservice
        ? [
          {
            key: 'create',
            text: t('DESIGNER.ACTION.CREATE_CONDITION'),
            iconProps: { iconName: 'TriggerAuto' },
            onClick: () => navigate('/microservices-designer/create/condition'),
          },
        ]
        : []),
      ...(canCreateCustomFieldsMicroservice
        ? [
          {
            key: 'create2',
            iconProps: { iconName: 'FieldChanged' },
            text: t('DESIGNER.ACTION.CREATE_CUSTOM_FIELDS'),
            onClick: () => navigate('/microservices-designer/create/custom-fields'),
          },
        ]
        : []),
    ] : []),
  ]

  return {
    handleOnClickViewDetails,
    onClickEdit,
    onClickUnarchive,
    onClickArchive,
    hideArchiveDialog,
    hideUnarchiveDialog,
    toggleHideArchiveDialog,
    toggleHideUnarchiveDialog,
    selectedMicroservice,
    onConfirmArchive,
    onConfirmUnarchive,
    commandBarActions
  }
}