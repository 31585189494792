export const useFlexxBuildOperationsLogBuildExport = () => {
	const operationLogsTableExportColumns = [
		{
			key: 'action',
			header: 'Action',
			width: 14,
			formulae: [`"Add/Update,Delete"`],
		},
		{
			key: 'businessName',
			header: 'Business',
			width: 20,
		},
		{
			key: 'flexxBuildProcessId',
			header: 'Flexx Build Process ID',
			width: 20,
		},
		{
			key: 'processStatus',
			header: 'Process Status',
			width: 20,
		},
		{
			key: 'processName',
			header: 'Process Name',
			width: 20,
		},
		{
			key: 'error',
			header: 'Error',
			width: 20,
		},
		{
			key: 'initiatorFeature',
			header: 'Initiator Feature',
			width: 20,
		},
		{
			key: 'created_at',
			header: 'Creation date',
			width: 10,
		},
		{
			key: 'updated_at',
			header: 'Updated date',
			width: 10,
		},
	]

	return { operationLogsTableExportColumns }
}