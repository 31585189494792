import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { MicroserviceFormType } from '../../pages/microservices/components/MicroserviceForm'

interface CreateMicroservicePayload {
  _id: string
}

interface Response {
  createMicroservice: CreateMicroservicePayload
}

const createMicroserviceQuery = gql`
  mutation Request($input: CreateMicroserviceGraphqlRequest!, $defaultLang: String!) {
    createMicroservice(input: $input, defaultLang: $defaultLang) {
      _id
    }
  }
`

const createMicroservice = async (
  microservice: MicroserviceFormType,
  defaultLang: string
): Promise<CreateMicroservicePayload> => {
  const { createMicroservice }: Response = await graphClient.request(createMicroserviceQuery, {
    input: microservice,
    defaultLang,
  })
  return createMicroservice
}

export const useCreateMicroservice = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['createMicroservice'],
    ({ microservice, defaultLang }: { microservice: MicroserviceFormType; defaultLang: string }) =>
      createMicroservice(microservice, defaultLang),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['microservices'])
        queryClient.invalidateQueries(['getPaginatedDesignerMicroservices'])
      },
    }
  )
}
