import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
}

export const useMicroservicesAuditLogFilters = ({ t }: Props) => {
  useLocalStorageSearchParams()

  const eventTypeOptions = [
    {
      key: 'CREATED',
      text: t('EVENT.CREATED'),
    },
    {
      key: 'UPDATED',
      text: t('EVENT.UPDATED'),
    },
    {
      key: 'ARCHIVED',
      text: t('EVENT.ARCHIVED'),
    },
    {
      key: 'UNARCHIVED',
      text: t('EVENT.UNARCHIVED'),
    },
    {
      key: 'ACTIVATED',
      text: t('EVENT.ACTIVATED'),
    },
    {
      key: 'DEACTIVATED',
      text: t('EVENT.DEACTIVATED'),
    },
    {
      key: 'EXECUTED',
      text: t('EVENT.EXECUTED'),
    },
  ]

  const filterFields: FilterField[] = [
    {
      field: 'microserviceName',
      text: t('AUDIT_LOG.TABLE.MICROSERVICE'),
      type: FieldTypes.STRING,
    },
    {
      field: 'type',
      text: t('AUDIT_LOG.TABLE.TYPE'),
      type: FieldTypes.ENUM,
      options: eventTypeOptions,
    },
    {
      field: 'status',
      text: t('AUDIT_LOG.TABLE.STATUS'),
      type: FieldTypes.STRING,
    },
    {
      field: 'initiatedBy',
      text: t('AUDIT_LOG.TABLE.INITIATED_BY'),
      type: FieldTypes.STRING,
    },
  ]

  return {
    filterFields,
  }
}
