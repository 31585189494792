import { Link, TooltipDelay, TooltipHost, TooltipOverflowMode } from "@fluentui/react"
import IconLanguage from "app/components/IconLanguage/IconLanguage"
import { CellWithGreenLink } from "app/components/table-page-body/CellWithGreenLink"
import { DesignerMicroserviceView } from "app/hooks/microservices/useGetDesignerMicroservices"
import { getTranslation } from "app/models/microservices"
import i18next from "i18next"

export const renderMicroserviceName = (item: DesignerMicroserviceView, handleOnClickViewDetails: (item: DesignerMicroserviceView) => void) => {
  const name = getTranslation(item.name, i18next.language)
  const itemName = name.text
  const showIcon = name.translatedByIA

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <TooltipHost
          content={itemName?.toString()}
          delay={TooltipDelay.zero}
          overflowMode={TooltipOverflowMode.Parent}
        >
          <CellWithGreenLink callback={() => handleOnClickViewDetails(item)}>
            {itemName?.toString()} {showIcon && <IconLanguage tam="16" />}
          </CellWithGreenLink>
        </TooltipHost>
      </div>
    </div>
  )
}