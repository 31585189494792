import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { Microservice } from '../../query-client/types'

const deactivateMicroserviceQuery = gql`
  mutation deactivateMicroservice($id: ID!, $businessId: ID!) {
    deactivateMicroservice(id: $id, businessId: $businessId) {
      _id
    }
  }
`

const deactivateMicroservice = async (id: string, businessId: string): Promise<Microservice> => {
  return graphClient
    .request(deactivateMicroserviceQuery, { id: id, businessId })
    .then((res) => res.deactivateMicroservice)
}

export const useDeactivateMicroservice = (businessId: string) => {
  const queryClient = useQueryClient()

  return useMutation(['deactivateMicroservice'], (id: string) => deactivateMicroservice(id, businessId), {
    onSuccess: (id) => {
      queryClient.invalidateQueries(['microservices'])
      queryClient.invalidateQueries(['microservices', id])
      queryClient.invalidateQueries(['microservices', businessId])
      queryClient.invalidateQueries(['enabled-microservices'])
      queryClient.invalidateQueries(['enabled-microservice', id._id, businessId])
      queryClient.invalidateQueries(['getPaginatedDesignerMicroservices'])
    },
  })
}
