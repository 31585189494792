import { DesignerMicroserviceView } from 'app/hooks/microservices/useGetDesignerMicroservices'
import { getTranslation } from 'app/models/microservices'
import { microserviceTypeLabel } from 'app/models/microservices/MicroserviceType'
import i18next, { TFunction } from 'i18next'

export const useMicroservicesDesignerBuildExport = (t: TFunction, canSeeNonStandardMicroservices: boolean) => {
  const exportColumns = [
    {
      key: 'name',
      header: t('DESIGNER.TABLE.NAME'),
      width: 10,
    },
    {
      key: 'category',
      header: t('MARKETPLACE.TABLE.CATEGORY'),
      width: 10,
    },
    ...(canSeeNonStandardMicroservices
      ? [
          {
            key: 'type',
            header: t('DESIGNER.TABLE.TYPE'),
            width: 10,
          },
        ]
      : []),
    {
      key: 'library',
      header: t('DESIGNER.TABLE.LIBRARY'),
      width: 10,
    },
    {
      key: 'archived',
      header: t('DESIGNER.TABLE.ARCHIVED'),
      width: 10,
    },
  ]

  const exportMapper = (item: DesignerMicroserviceView) => {
    const name = getTranslation(item.name, i18next.language).text
    const category = getTranslation(item.category, i18next.language).text
    const library = item.organization.name
    const type = microserviceTypeLabel(t)[item.type]
    const archived = item.archivedAt ? t('DESIGNER.ARCHIVED') : t('DESIGNER.UNARCHIVED')

    return {
      name,
      category,
      library,
      type,
      archived,
    }
  }

  return { exportColumns, exportMapper }
}
