import { TFunction } from 'i18next'
import { ICommandItem } from '@flexxibleit/flexxible-ui'

interface Props {
  t: TFunction
  onExportClicked: () => Promise<void>
}

export const useInstalledAppVersionsActions = ({
  t,
  onExportClicked,
}: Props): {
  commandBarActions: ICommandItem[]
} => {
  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  return { commandBarActions }
}
