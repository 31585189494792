import { TooltipDelay, TooltipHost, TooltipOverflowMode } from '@fluentui/react'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'
import { MarketplaceMicroserviceView } from 'app/hooks/microservices/useGetMarketplaceMicroservices'
import { getTranslation } from 'app/models/microservices'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import i18next from 'i18next'

type Props = {
  item: MarketplaceMicroserviceView
  onClick: (item: MarketplaceMicroserviceView) => void
}

export const MicroserviceName = ({ item, onClick }: Props) => {
  const name = getTranslation(item.name, i18next.language)
  const itemName = name.text
  const showIcon = name.translatedByIA

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <TooltipHost content={itemName?.toString()} delay={TooltipDelay.zero} overflowMode={TooltipOverflowMode.Parent}>
          <CellWithFeatureGreenLink feature={FEATURE_NAMES.MICROSERVICES_READ} callback={() => onClick(item)}>
            {itemName?.toString()} {showIcon && <IconLanguage tam="16" />}
          </CellWithFeatureGreenLink>
        </TooltipHost>
      </div>
    </div>
  )
}
