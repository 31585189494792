import { Stack } from "@fluentui/react";
import { Module } from "app/query-client/types";
import { getModuleIcon } from "app/services/module-icon.service";

export const H2Card = ({ module, index, onClick, getModuleType }: { module: Partial<Module>; index: number; onClick: Function, getModuleType: Function }) => {
  return (
    <>
      <Stack horizontal key={`${index}`}>
        <Stack
          tokens={{ childrenGap: 5 }}
          horizontal
          verticalAlign="center"
          style={{ cursor: 'pointer' }}
          onClick={() => onClick(module.url)}
        >
          <Stack.Item style={{ display: 'flex' }}>{getModuleIcon(module.type || '', 24, 24)}</Stack.Item>
          <Stack.Item
            style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {module.type === 'custom' ? module.name : getModuleType(module.type || '')}
          </Stack.Item>
          <Stack.Item style={{ fontSize: 12 }}>{module.tag} </Stack.Item>
        </Stack>
      </Stack>
    </>
  )
}