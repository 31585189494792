import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { ColumnActionsMode, TooltipHost, TooltipOverflowMode } from '@fluentui/react'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { TFunction } from 'react-i18next'

export interface FlexxBuildProcess {
  _id: string
  business: {
    _id: string
    name: string
  }
  flexxBuildProcessId: string
  processStatus: string
  processName: string
  error: string
  initiatorFeature: string
  created_at: string
  updated_at: string
}

type Props = {
  t: TFunction
}

export const useFlexxBuildOperationsLogBuildTable = ({ t }: Props) => {
  const columns: IPaginatedTableColumn[] = [
    {
      key: 'businessName',
      name: t('AUDIT_LOG.TABLE.BUSINESS', 'Business'),
      fieldName: 'businessName',
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        return item.business.name
      },
    },
    {
      key: 'flexxBuildProcessId',
      name: t('AUDIT_LOG.TABLE.FLEXX_BUILD_PROCESS_ID', 'Flexx Build Process ID'),
      fieldName: 'flexxBuildProcessId',
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processStatus',
      name: t('AUDIT_LOG.TABLE.PROCESS_STATUS', 'Process Status'),
      fieldName: 'processStatus',
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processName',
      name: t('AUDIT_LOG.TABLE.PROCESS_NAME', 'Process Name'),
      fieldName: 'processName',
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'error',
      name: t('AUDIT_LOG.TABLE.ERROR', 'Error'),
      fieldName: 'error',
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        return (
          <TooltipHost id={item._id} content={item.error} overflowMode={TooltipOverflowMode.Parent}>
            <span aria-describedby={item._id}>{item.error}</span>
          </TooltipHost>
        )
      },
      className: 'cell-ellipsis',
    },
    {
      key: 'initiatorFeature',
      name: t('AUDIT_LOG.TABLE.INITIATOR_FEATURE', 'Initiator Feature'),
      fieldName: 'initiatorFeature',
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'created_at',
      name: t('AUDIT_LOG.TABLE.CREATED_AT', 'Created At'),
      fieldName: 'created_at',
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        const date = new Date(Number(item.created_at))
        return dateFormatter(t, date, DateFormat.DATETIME)
      },
    },
    {
      key: 'updated_at',
      name: t('AUDIT_LOG.TABLE.UPDATED_AT', 'Updated At'),
      fieldName: 'updated_at',
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        const date = new Date(Number(item.updated_at))
        return dateFormatter(t, date, DateFormat.DATETIME)
      },
    },
  ]

  return {
    columns,
  }
}
