import graphClient from 'app/query-client/graphClient'
import { PaginationArgs } from 'app/query-client/types'
import { useSearchParams } from 'react-router-dom'
import { QUERY_PARAMS } from 'app/config/queryParams.const'
import { useQuery } from '@tanstack/react-query'
import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'

type FilterParams = {
  [key: string]: any
}

type SortingParams = {
  sortBy: string
  sortOrder: string
}

function getPaginatedData<T>(
  query: string,
  queryName: string,
  commonVariables: {
    searchTerm: string
    filters: FilterParams
    sorting: SortingParams
    page: number
    perPage: number
  },
  variables: Record<string, any>
) {
  const commonVariablesWithForcedFilters = {
    ...commonVariables,
    filters: { ...commonVariables.filters, ...variables.filters },
  }
  delete variables.filters
  return graphClient.request(query, { ...commonVariablesWithForcedFilters, ...variables }).then((response) => {
    return {
      results: response[queryName].data as T[],
      pageInfo: response[queryName].pageInfo,
    }
  })
}

function getExportData<T>(
  query: string,
  queryName: string,
  commonVariables: {
    searchTerm: string
    filters: FilterParams
    sorting: SortingParams
    page: number
    perPage: number
  },
  variables: Record<string, any>
) {
  const commonVariablesWithForcedFilters = {
    ...commonVariables,
    page: 1,
    perPage: 2147483647,
    searchTerm: '',
    filters: { ...variables.filters },
  }
  delete variables.filters
  return graphClient.request(query, { ...commonVariablesWithForcedFilters, ...variables }).then((response) => {
    return response[queryName].data as T[]
  })
}

export default function usePaginatedDataAndExport<T>(
  query: string,
  queryName: string,
  filters: FilterField[] = [],
  variables: Record<string, any> = {}
) {
  const [searchParams] = useSearchParams()
  const searchTerm = searchParams.get(QUERY_PARAMS.SEARCH_TERM) || ''

  const filtersAndSorting = Object.fromEntries(searchParams.entries())
  const filterKeys = Object.keys(filtersAndSorting).filter((key) => filters.some((f) => f.field === key))

  let filtersParams: FilterParams = {}
  filterKeys.forEach((key) => {
    const filter = filters.find((f) => f.field === key)
    filtersParams[key] = filter?.type === FieldTypes.ENUM ? searchParams.getAll(key) : searchParams.get(key)
  })

  const sortingParams: SortingParams = {
    sortBy: filtersAndSorting.sortBy,
    sortOrder: filtersAndSorting.sortOrder,
  }

  const paginationParams: PaginationArgs = {
    page: Number(filtersAndSorting.page) || 1,
    perPage: Number(filtersAndSorting.perPage) || 10,
  }

  const commonVariables = {
    searchTerm,
    filters: filtersParams,
    sorting: sortingParams,
    page: paginationParams.page,
    perPage: paginationParams.perPage,
  }

  const paginatedQuery = useQuery(
    [queryName, query, commonVariables, variables],
    () => getPaginatedData<T>(query, queryName, commonVariables, variables),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )

  const exportQuery = useQuery(
    [queryName, query, commonVariables, variables, 'export'],
    () => getExportData<T>(query, queryName, commonVariables, variables),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )

  return {
    paginatedQuery,
    exportQuery,
  }
}
