import { TFunction } from 'i18next';

import { ICommandItem } from '@flexxibleit/flexxible-ui';
import { ProductConfig } from 'app/query-client/types';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ModuleTypes } from 'app/pages/organization-details/modules/module-form/types';

export const useHomeActions = (t: TFunction, onExportClicked: () => void) => {

  const navigate = useNavigate()

  const openViewProduct = (product: ProductConfig) => {
    navigate(`/products/${product._id}`)
  }

  const getModuleType = (moduleType: string): string => {
    return ModuleTypes.find((type: any) => (type.key as string) === moduleType)?.text || ''
  }

  const handleClick = (url?: string) => {
    window.open(url, '_blank')
  }

  const handleClickDownload = (navigate: NavigateFunction, selectedOrganizationId: string) => {
    navigate(`/organization/${selectedOrganizationId}/reporting-groups`)
  }

  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked
    }
  ]

  return {
    openViewProduct,
    getModuleType,
    handleClick,
    handleClickDownload,
    commandBarActions
  }
}