import graphClient from 'app/query-client/graphClient'
import { PaginationArgs } from 'app/query-client/types'
import { useSearchParams } from 'react-router-dom'
import { QUERY_PARAMS } from 'app/config/queryParams.const'
import { useQuery } from '@tanstack/react-query'
import { FilterField, FieldTypes } from '@flexxibleit/flexxible-ui'

interface PageInfo {
  hasNextPage: boolean
  hasPreviousPage: boolean
  totalPages: number
  currentPage: number
  total: number
}

type PaginatedResponse<T, Metadata> = {
  results: T[]
  pageInfo: PageInfo
} & Metadata

type FilterParams = {
  [key: string]: any
}

type SortingParams = {
  sortBy: string
  sortOrder: string
}

function getPaginatedData<T, Metadata = object>(
  query: string,
  queryName: string,
  commonVariables: {
    searchTerm: string
    filters: FilterParams
    sorting: SortingParams
    page: number
    perPage: number
  },
  variables: Record<string, any>
): Promise<PaginatedResponse<T, Metadata>> {
  const commonVariablesWithForcedFilters = {
    ...commonVariables,
    filters: { ...commonVariables.filters, ...variables.filters },
  }
  delete variables.filters
  return graphClient.request(query, { ...commonVariablesWithForcedFilters, ...variables }).then((response) => {
    const { data, pageInfo, ...rest } = response[queryName]

    const result: PaginatedResponse<T, Metadata> = {
      results: data,
      pageInfo: pageInfo,
      ...rest,
    }

    return result
  })
}

export default function usePaginatedData<T, Metadata = object>(
  query: string,
  queryName: string,
  filters: FilterField[] = [],
  variables: Record<string, any> = {}
) {
  const [searchParams] = useSearchParams()
  const searchTerm = searchParams.get(QUERY_PARAMS.SEARCH_TERM) || ''

  const filtersAndSorting = Object.fromEntries(searchParams.entries())
  const filterKeys = Object.keys(filtersAndSorting).filter((key) => filters.some((f) => f.field === key))

  let filtersParams: FilterParams = {}
  filterKeys.forEach((key) => {
    const filter = filters.find((f) => f.field === key)

    if (filter?.type === FieldTypes.BOOLEAN) {
      filtersParams[key] = filtersParams[key] === 'true'
    } else if (filter?.type === FieldTypes.ENUM) {
      filtersParams[key] = searchParams.getAll(key)
      if (filtersParams[key].some((value: string) => value === '') || filtersParams[key].length === 0) {
        delete filtersParams[key]
      }
      //WORKAROUND: until boolean filter is changed to an enum of value, we have to do like this
      else if (filtersParams[key].some((value: string) => value.includes('boolean_'))) {
        if (filtersParams[key].length >= 2) {
          delete filtersParams[key]
        } else {
          filtersParams[key] = filtersParams[key][0] === 'boolean_true'
        }
      }
    } else {
      filtersParams[key] = searchParams.get(key)
    }

    if (filter?.type === FieldTypes.NUMBER) {
      filtersParams[key] = Number(searchParams.get(key))
    }
  })

  const sortingParams: SortingParams = {
    sortBy: filtersAndSorting.sortBy,
    sortOrder: filtersAndSorting.sortOrder,
  }

  const paginationParams: PaginationArgs = {
    page: Number(filtersAndSorting.page) || 1,
    perPage: Number(filtersAndSorting.perPage) || 50,
  }

  const commonVariables = {
    searchTerm,
    filters: filtersParams,
    sorting: sortingParams,
    page: paginationParams.page,
    perPage: paginationParams.perPage,
  }

  return useQuery(
    [queryName, query, commonVariables, variables],
    () => {
      return getPaginatedData<T, Metadata>(query, queryName, commonVariables, variables)
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
